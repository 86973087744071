<template>
	<div class="container mt-3 mh-65">
		<h2>Health and Safety</h2>
		<p>As a new employee with Northumberland Hills Hospital you are required to complete the Worker Health and Safety Training in 4 Steps, Ontario Ministry of Labour.</p>
		<hr />
		<div class="d-flex justify-content-end">
			<button type="button" class="btn btn-light" @click="$router.go(-1)">Back to Dashboard</button>
		</div>
		<hr />
		<h3>Instructions:</h3>
		<ol>
			<li>
				Please go to the following website: <br />
				<a href="https://www.labour.gov.on.ca/english/hs/elearn/worker/foursteps.php" target="_blank">https://www.labour.gov.on.ca/english/hs/elearn/worker/foursteps.php</a>
			</li>
			<li>Complete the Quiz at the Website Above</li>
			<li>Download the Certificate that will be issued once the Quiz is completed.</li>
			<li>Return to this Page and Upload Your Completed Certificate</li>
		</ol>
		<hr />
		<p class="text-center text-muted" v-if="certs.length<=0">You have not uploaded any quiz results yet.</p>
		<div v-if="certs.length>0">
			<table class="table">
				<thead>
					<tr>
						<th>Filename</th>
						<th colspan="2">Type</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="c in certs" :key="c.employee_document_id">
						<td>{{c.filename}}</td>
						<td>{{c.document_type_id}}</td>
						<td>
							<button type="button" class="btn btn-dark" @click="delete_document(c.employee_document_id)"><i class="bi bi-trash"></i></button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<hr />
		<div class="mb-5">
			<label class="form-label" for="cert-upload">Upload your Quiz Results below.</label>
			<CIFileUploader action="api/employee/upload" input-name="quiz_file" @after-upload="load_documents" :extra-params="{type:'health_safety_quiz'}" class="mb-5" />
		</div>
		<div class="d-flex justify-content-center mb-5">
			<button type="button" class="btn btn-light" @click="$router.go(-1)">Back to Dashboard</button>
		</div>
	</div>
</template>
<script>
	import CIFileUploader from '@/components/CIFileUploader.vue'
	export default { 
		name: 'HealthAndSafetyView',
		components: {CIFileUploader},
		data(){return{
			certs: [],
			userinfo: {},
		}},
		methods: {
			/** 
			 * Load quiz result files from the server as a list
			 */
			load_documents(){
				let v = this
				v.axios.get('api/hs_quiz').then(response=>{
					v.certs = response.data
				})
			},
			delete_document(employee_document_id){
				let v = this

				v.$confirm({
					text:'Are you sure you want to remove this Quiz Result?',
					success(){
						v.axios.delete('api/employee/document/'+String(employee_document_id)).then(response=>{
							v.$toast('Quiz Removed','success')
							v.load_documents()
						})
					}
				})
			},
		},
		mounted(){
			let v = this
			v.userinfo = v.get_token_info()
			v.load_documents()
		}
	}
</script>
 
