<template>
	<div class="container mh-65" id="top">
		<h2>NORTHUMBERLAND HILLS HOSPITAL STATEMENT OF CONFIDENTIALITY</h2>
		<h3 class="mt-5">PREAMBLE</h3>
		<p>Breach of confidentiality includes, but is not limited to i) accessing a patient health record without authorization to do so or without the “Need to Know for Direct Patient Care” or “to perform the work of the organization” ii) sharing of other Hospital business or staff information that is recognized as confidential material.</p>
		<ol>
			<li>Accessing the medical record of :
				<ul>
					<li>oneself</li>
					<li>a family member</li>
					<li>a neighbour, friend; and</li>
					<li>a patient that you do not have a “Need to Know for Direct Patient Care”.</li>
				</ul>
			</li>
			<li>Looking up address/date of birth of staff, friend, neighbours etc.</li>
			<li>Discussing patient situations in public areas, (e.g. hallways, cafeteria and elevators) even without mentioning names as patients can be identified by facts other than names.</li>
			<li>Taking examples of work incidents involving patient or staff home for a family “lesson”. (Patients can be identified by facts other than names).</li>
			<li>Disclosing/sharing information about staff e.g. medical information, work performance issues, salaries, work schedules and telephone numbers with non-hospital staff etc. that through your position, might be privileged to know.</li>
			<li>Disclosing/sharing information on vendor contracts.</li>
		</ol>
		<p>Furthermore, there is an expectation that all Hospital personnel, Medical Staff, Board Members, Volunteers and Students will continuously monitor the adherence of these policies and may include addressing individuals seen to be in breach OR to report breaches to their immediate supervisor.</p>

		<hr />

		<div class="alert alert-warning" v-show="employee.confidentiality_agreement!=1"><strong>You must complete the acceptance section at the bottom of this page.</strong></div>
		<div class="alert alert-success" v-show="employee.confidentiality_agreement==1"><i class="bi bi-check2-circle"></i> You have completed this agreement.</div>

		<div class="border p-3">
			<h4>CONFIDENTIALITY AGREEMENT</h4>
			<p>I, <span class="ms-2 me-2 ps-5 pe-5 d-inline-block border-bottom signature-font fs-3"><strong>{{signature_display}}</strong></span>, do solemnly affirm, that I will faithfully fulfill my duties as a member of the Hospital Staff (&/or any of the following: Medical Staff, Volunteer, Board Member, Student or Affiliate) will observe and comply with the policies and procedures of the NORTHUMBERLAND HILLS HOSPITAL facility. Except when I am legally authorized or required to do so for DIRECT PATIENT CARE, I will not inspect or receive information from a patient’s record or from notes, charts and other material related to patient care and I will not disclose or give to any person any information or document that comes to my knowledge or possession by reason of my being an Employee, Volunteer, Board Member, Student, Medical Staff, other Hospital personnel or affiliate. Neither will I share Hospital business information that is recognized as confidential information. I understand that a breach of this oath could lead to discipline up to and including termination of employment, privileges or affiliation with the Hospital as applicable.</p>
		</div>

		<p><strong>Please Click below to E-Sign and agree to this Confidentiality Agreement.</strong></p>
		<div class="row mb-5">
			<div class="col-sm-12 col-md-8">
				<div class="form-check pe-5">
					<input type="checkbox" class="form-check-input" :true-value="1" :false-value="0" v-model="employee.confidentiality_agreement" id="agreement1">
					<label class="form-check-label ps-5" for="agreement1">I, <span class="ms-2 me-2 ps-5 pe-5 d-inline-block border-bottom signature-font fs-3"><strong>{{signature_display}}</strong></span> attest that I have reviewed and completed all the modules as outlined above.</label>
				</div>
			</div>
			<div class="col-sm-12 col-md-4">
				<label class="form-label" for="agreement2">Signed on</label>
				<input type="datetime-local" class="form-control" id="agreement2" v-model="employee.confidentiality_agreement_date" readonly>
			</div>
		</div>
		<div class="alert alert-success" v-show="employee.confidentiality_agreement==1"><i class="bi bi-check2-circle"></i> You have completed this agreement.</div>
		<hr />
		<div class="d-flex justify-content-center justify-content-md-end mb-5">
			<router-link type="button" class="btn btn-light mt-1 me-1" to="/">Cancel, Go Back</router-link>
			<button type="button" class="btn btn-light mt-1 me-1" @click="save_employee(true)">Save &amp; Stay on the Page</button>
			<button type="button" class="btn btn-light mt-1" @click="save_employee(false)">Save &amp; Go Back</button>
		</div>
	</div>
</template>
<script>
	
	const moment = require('moment')

	export default{
		name: 'ConfidentialityView',
		data(){return{
			userinfo: {},
			stats: {},
			employee: {
				confidentiality_agreement: null,
				confidentiality_agreement_date: null,
			},
			employee_loaded: false,
			signature_display: null, // Temp used to display the name IF the user has signed the agreement
			save_count: 0,
		}},
		watch: {
			'employee.confidentiality_agreement': function(newvalue){
				let v = this
				if( newvalue == 1 )
				{
					v.signature_display = v.userinfo.fullname
					v.employee.confidentiality_agreement_date = moment().format(v.formats.datetime.html)
				}
				else
				{
					v.signature_display = null
					v.employee.confidentiality_agreement_date = null
				}
			},
		},
		methods: {
			load_employee(employee_id){
				// Loads the Employee Record
				let v = this
				if( employee_id == 0 )
				{
					// Only the couple fields are needed for the update
					v.employee = {
						employee_id: 0,
						confidentiality_agreement: null,
						confidentiality_agreement_date: null,
					}
					v.employee_loaded = true
				}
				else
				{
					v.axios.get('/api/employee/'+String(employee_id)).then(response=>{
						v.employee = response.data.employee
						v.stats = response.data.stats
						v.employee_loaded = true
					})
				}
			},
			save_employee(stay_on_page=true){
				const v = this
				v.axios.post('/api/employee',v.employee).then(response=>{
					v.$toast('Information Saved','success');
					v.employee = response.data.employee
					v.save_count++
					if( !stay_on_page )
						v.$router.push('/')
				})
				
			},
		},
		mounted(){
			let v = this
			v.userinfo = v.get_token_info()
			v.load_employee(v.userinfo.id)
		},
	}
</script> 
