<template>
	<div class="container mh-60">
		<h1>Reference Documents</h1>
		<p>These are reference documents made available to all personnel who visit the site.</p>
		<div id="add-new-doc" w-50>
			<h2>Upload a New Document</h2>
			<CIFileUploader action="api/document/upload" input-name="documents" multiple="true" @after-upload="load_documents"></CIFileUploader>
		</div>

		<hr />
		<table class="table">
			<thead>
				<tr>
					<th>Sort</th>
					<th>Title</th>
					<th>Document</th>
					<th colspan="2">Created</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="d in documents" :key="d.reference_document_id">
					<td>
						<a href="javascript:void(0)" @click="move_up(d)"><i class="bi bi-caret-up-fill"></i></a>
						<a href="javascript:void(0)" @click="move_down(d)"><i class="bi bi-caret-down-fill"></i></a>
					</td>
					<td>{{d.title}}</td>
					<td>
						<a class="ms-1 mt-1" :href="'/reference_documents/'+String(d.filename)" target="_blank"><i class="bi bi-arrow-bar-down"></i> {{d.filename}}</a>
					</td>
					<td>{{d.created}}</td>
					<td class="text-nowrap">
						<router-link class="btn btn-light btn-sm ms-1" :to="'/admin/doc/'+String(d.reference_document_id)"><i class="bi bi-pencil-square"></i> Edit</router-link>
						<button type="button" class="btn btn-light btn-sm ms-1" @click="delete_document(d.reference_document_id)" aria-label="Delete Document" title="Delete Document"><i class="bi bi-trash"></i> Delete</button>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
	import CIFileUploader from '@/components/CIFileUploader.vue'
	export default {
		name: 'RefDocsAdminView',
		components: {CIFileUploader},
		data(){return{
			documents: [],
		}},
		methods: {
			load_documents(){
				let v = this
				v.axios.get('api/documents').then(response=>{
					v.documents = response.data
				})
			},
			delete_document(reference_document_id){
				let v = this

				v.$confirm({
					text:'Are you sure you want to delete this document?',
					success(){
						v.axios.delete('api/document/'+String(reference_document_id)).then(response=>{
							v.$toast('Document Deleted')
							v.load_documents()
						})
					}
				})
			},
			move_up(doc){
				const v = this
				v.axios.get('api/document/sort_up/'+String(doc.reference_document_id)).then(response=>{
					v.load_documents()
				})
			},
			move_down(doc){
				const v = this
				v.axios.get('api/document/sort_down/'+String(doc.reference_document_id)).then(response=>{
					v.load_documents()
				})
			},
		},
		mounted(){
			let v = this
			v.load_documents()
		},
	}
</script> 
