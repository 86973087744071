<template>
	<div class="container mh-65">
		<h2>Admin Dashboard</h2>
		<div class="row">
			<!-- <div class="col-sm-12 col-md-6">
				<div class="card">
					<div class="card-header">
						Notifications
					</div>
					<div class="card-body">
						<h5 class="card-title">PENDING</h5>
						<p class="card-text">PENDING</p>
					</div>
				</div>
			</div> -->
			<div class="col-sm-12">
				<div class="card">
					<div class="card-header">
						Recent Activity
					</div>
					<div class="card-body" style="max-height: 20em; overflow: scroll;">
						<div v-for="a,idx in activity" :key="idx" class="row">
							<div class="col">
								{{a.message}}
							</div>
							<div class="col text-end">
								{{a.logged}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default{
		name: 'AdminDashboardView',
		data(){return{
			activity: [],
		}},
		methods: {
			load_activity(){
				let v = this
				v.axios.get('api/latestactivity').then(response=>{
					v.activity = response.data
				})
			},
		},
		mounted(){
			this.load_activity()
		},
	}
</script> 
