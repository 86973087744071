<template>
	<div class="container mh-65" id="top">
		<h2>Mandatory Pre-Employment Orientation/ Training at NHH – Attestation Form</h2>
		<p>All staff at Northumberland Hills Hospital are <u><strong>required to review and complete new hire orientation material prior to the commencement of their employment with NHH</strong></u>.</p>
		<div class="alert alert-warning" v-show="employee.newhire_attestation!=1"><strong>You must complete the acceptance section at the bottom of this page.</strong></div>
		<div class="alert alert-success" v-show="employee.newhire_attestation==1"><i class="bi bi-check2-circle"></i> You have completed this attestation.</div>
		<div class="border p-3">
			<p><u><strong>The following information is required for you to review prior to employment:</strong></u></p>
			<ul>
				<li>Emergency Response Codes – Reference Sheet</li>
				<li>Emergency Procedures Reminder</li>
				<li>Respectful Workplace Policy and PowerPoint Presentation</li>
				<li>WHMIS PowerPoint Presentation</li>
				<li>Infection Protection and Control – PowerPoint presentation</li>
				<li>How May I Help You? AODA guide</li>
				<li>Ministry of Labour Fact Sheet</li>
				<li>Corporate Dress Code Policy</li>
				<li>Occupational Health and Safety Program</li>
				<li>Reporting an Absence Policy</li>
			</ul>
			<p>The following forms/ documents are to be signed and returned to Human Resources:</p>
			<ul>
				<li>Statement of Confidentiality </li>
				<li>Values and Corresponding Behaviour</li>
				<li>Staff Survey – French Language Skills</li>
				<li>New Employee Information</li>
				<li>TD1 – Personal Tax Exemption Form</li>
				<li>Authorization to Deduct Parking (if required)</li>
			</ul>
			<p>As a new employee with Northumberland Hills Hospital you are required to complete the Worker Health and Safety Training in 4 Steps, Ontario Ministry of Labour.  Once completed you will receive a certificate which must be supplied to human resources.</p>
		</div>
		<p><strong>Please Click below to E-Sign and agree to this Attestation.</strong></p>
		<p>I understand the information provided and further commit to asking for guidance or clarity as needed.</p>
		<p>I further understand that failure to complete the above information prior to commencement of employment may delay my employment at NHH.</p>
		<div class="row mb-5">
			<div class="col-sm-12 col-md-8">
				<div class="form-check pe-5">
					<input type="checkbox" class="form-check-input" :true-value="1" :false-value="0" v-model="employee.newhire_attestation" id="attestation1">
					<label class="form-check-label ps-5" for="attestation1">I, <span class="ms-2 me-2 ps-5 pe-5 d-inline-block border-bottom signature-font fs-3"><strong>{{signature_display}}</strong></span> attest that I have reviewed and completed all the modules as outlined above.</label>
				</div>
			</div>
			<div class="col-sm-12 col-md-4">
				<label class="form-label" for="attestation2">Signed on</label>
				<input type="datetime-local" class="form-control" id="attestation2" v-model="employee.newhire_attestation_date" readonly>
			</div>
		</div>
		<div class="alert alert-success" v-show="employee.newhire_attestation==1"><i class="bi bi-check2-circle"></i> You have completed this attestation.</div>
		<hr />
		<div class="d-flex justify-content-center justify-content-md-end mb-5">
			<router-link type="button" class="btn btn-light mt-1 me-1" to="/">Cancel, Go Back</router-link>
			<button type="button" class="btn btn-light mt-1 me-1" @click="save_employee(true)">Save &amp; Stay on the Page</button>
			<button type="button" class="btn btn-light mt-1" @click="save_employee(false)">Save &amp; Go Back</button>
		</div>
	</div>
</template>
<script>
	
	const moment = require('moment')

	export default{
		name: 'AttestationView',
		data(){return{
			userinfo: {},
			stats: {},
			employee: {
				newhire_attestation: null,
				newhire_attestation_date: null,
			},
			employee_loaded: false,
			signature_display: null, // Temp used to display the name IF the user has signed the attestation
			save_count: 0,
		}},
		watch: {
			'employee.newhire_attestation': function(newvalue){
				let v = this
				if( newvalue == 1 )
				{
					v.signature_display = v.userinfo.fullname
					v.employee.newhire_attestation_date = moment().format(v.formats.datetime.html)
				}
				else
				{
					v.signature_display = null
					v.employee.newhire_attestation_date = null
				}
			},
		},
		methods: {
			load_employee(employee_id){
				// Loads the Employee Record
				let v = this
				if( employee_id == 0 )
				{
					// Only the couple fields are needed for the update
					v.employee = {
						employee_id: 0,
						newhire_attestation: null,
						newhire_attestation_date: null,
					}
					v.employee_loaded = true
				}
				else
				{
					v.axios.get('/api/employee/'+String(employee_id)).then(response=>{
						v.employee = response.data.employee
						v.stats = response.data.stats
						v.employee_loaded = true
					})
				}
			},
			save_employee(stay_on_page=true){
				const v = this
				v.axios.post('/api/employee',v.employee).then(response=>{
					v.$toast('Information Saved','success');
					v.employee = response.data.employee
					v.save_count++
					if( !stay_on_page )
						v.$router.push('/')
				})
				
			},
		},
		mounted(){
			let v = this
			v.userinfo = v.get_token_info()
			v.load_employee(v.userinfo.id)
		},
	}
</script> 
