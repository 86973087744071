<style scoped>
	.identity-thumb{ max-width: 150px; max-height: 200px; }
	.hover:hover{cursor:pointer; opacity:0.9;}
</style>
<template>
	<div class="container" style="margin-bottom: 300px;" id="top">
		<form @submmit.prevent="">
			<h2><i class="bi bi-person-bounding-box"></i> Employee Information</h2>
			<hr />
			<div class="alert alert-danger text-black" v-if="warnings.length>0 && save_count > 0 ">
				<h5 class="text-black">Warnings</h5>
				<ol>
					<li v-for="w,idx in warnings" :key="idx">
						{{w}}
					</li>
				</ol>
			</div>
			<div class="d-flex justify-content-center justify-content-md-end">
				<router-link type="button" class="btn btn-light mt-1 me-1" to="/"><i class="bi bi-box-arrow-left"></i> Cancel, Go Back</router-link>
				<button type="button" class="btn btn-light mt-1 me-1" @click="save_employee(true)"><i class="bi bi-box-arrow-down"></i> Save &amp; Stay on the Page</button>
				<button type="button" class="btn btn-light mt-1" @click="save_employee(false)"><i class="bi bi-box-arrow-right"></i> Save &amp; Go Back</button>
			</div>
			<section v-if="employee_loaded" class="mt-5">
				<h3>Identity</h3>
				
				<div class="row">
					<div class="col-sm-12 col-md-4">
						<label class="form-label" for="given_name">Given Name(s)</label>
						<input type="text" class="form-control" id="given_name" v-model="employee.given_name" :required="rules.given_name.required">
					</div>
					<div class="col-sm-12 col-md-4">
						<label class="form-label" for="middle_name">Middle Name(s)</label>
						<input type="text" class="form-control" id="middle_name" v-model="employee.middle_name">
					</div>
					<div class="col-sm-12 col-md-4">
						<label class="form-label" for="last_name">Last Name(s)</label>
						<input type="text" class="form-control" id="last_name" v-model="employee.last_name" :required="rules.last_name.required">
					</div>
				</div>
				<div class="row">
					<div class="col-sm-12 col-md-4">
						<label class="form-label" for="preferred_name">Your Preferred Name</label>
						<input type="text" class="form-control" id="preferred_name" v-model="employee.preferred_name">
					</div>
					<div class="col-sm-12 col-md-8">
						<label class="form-label" for="previous_last_name">Previous Last Name(s)</label>
						<input type="text" class="form-control" id="previous_last_name" v-model="employee.previous_last_name">
					</div>
				</div>
			</section>
			<hr />
			<section class="mt-5">
				<div class="row">
					<h3>Personal Details</h3>
					<div class="col-sm-12 col-md-4 col-lg-3">
						<label class="form-label" for="sin1">SIN Number</label>
						<div class="row">
							<div class="col">
								<input type="text" class="form-control text-center" id="sin1" v-model="sin_number.one" :maxlength="3" :required="rules.sin_number.required">
							</div>
							<div class="col">
								<input type="text" class="form-control text-center" id="sin2" v-model="sin_number.two" :maxlength="3" :required="rules.sin_number.required">
							</div>
							<div class="col">
								<input type="text" class="form-control text-center" id="sin3" v-model="sin_number.three" :maxlength="3" :required="rules.sin_number.required">
							</div>
						</div>
					</div>
					<div class="col-sm-12 col-md-4 col-lg-3">		
						<label class="form-label" for="birth">Date of Birth</label>
						<input type="date" v-model="employee.birth" id="birth" class="form-control" :required="rules.birth.required">
					</div>
					<div class="col-sm-12 col-md-4 col-lg-3">
						<label class="form-label" for="gender">Gender</label>
						<div class="row">
							<div class="col">
								<div v-if="!alt_gender_toggle">
									<select class="form-select" v-model="employee.gender" id="gender" :required="rules.gender.required">
										<option>Female</option>
										<option>Male</option>
										<option value="other">Specify Your Own Gender</option>
									</select>
								</div>
								<div v-if="alt_gender_toggle">
									<div class="input-group">
										<!-- <label class="form-label" for="gender_input">Please Enter Your Gender</label> -->
										<input type="text" v-model="employee.gender" class="form-control" id="gender_input" placeholder="Enter your Gender" :required="rules.gender.required">
										<button class="btn btn-outline-secondary" type="button" @click="alt_gender_toggle=false"><i class="bi bi-x"></i></button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<hr />
			<section class="mt-5">
				<div class="row">
					<h3>Contact Information</h3>
					<p>The information here will be used to contact you or send correspondence to you related to your employment with the hospital.</p>
					<div class="row">
						<div class="col-sm-12 col-md-6 col-lg-4">
							<h4 class="mt-3">Mailing Address</h4>
							<div>
								<label class="form-label" for="street">Street</label>
								<input type="text" class="form-control" id="street" v-model="employee.street" placeholder="eg. 30 Somerville Street" :required="rules.street.required">
							</div>
							<div>
								<label class="form-label" for="street">City or Town</label>
								<input type="text" class="form-control" id="street" v-model="employee.city" placeholder="eg. Belleville" :required="rules.city.required">
							</div>
							<div>
								<label class="form-label" for="street">Province</label>
								<!-- <input type="text" class="form-control" id="street" v-model="employee.province"> -->
								<select class="form-select" v-model="employee.province" :required="rules.province.required">
									<option v-for="province,idx in provinces" :key="idx">{{province}}</option>
								</select>
							</div>
							<div>
								<label class="form-label" for="street">Postal Code</label>
								<input type="text" class="form-control" id="street" v-model="employee.postal_code" placeholder="eg. K9V 3G9" :required="rules.postal_code.required">
							</div>
						</div>
						<div class="col-sm-12 col-md-6 col-lg-4">
							<h4 class="mt-3">Primary Contact Details</h4>
							<p>
								Provide the <strong>primary methods</strong> of contacting you by phone or by email.
							</p>
							<div>
								<label class="form-label" for="primary_contact_phone">Phone Number</label>
								<input type="text" class="form-control" id="primary_contact_phone" v-model="employee.primary_contact_phone" placeholder="eg. 4168886666" :required="rules.primary_contact_phone.required">
								<p class="text-muted"><small>Phone number is free form, extensions are allowed.</small></p>
							</div>
							<div>
								<label class="form-label" for="primary_contact_email">Email Address</label>
								<input type="email" class="form-control" id="primary_contact_email" v-model="employee.primary_contact_email" placeholder="eg. s.hopkins@somewhere.com" :required="rules.primary_contact_email.required">
							</div>
						</div>
					</div>
				</div>
			</section>
			<hr />
			<section class="mt-5">
				<div class="row">
					<div class="col">
						<h3>Emergency Contacts</h3>
						<p>You must provide <strong>two</strong> emergency contacts.</p>
						<div class="row">
							<div class="col-sm-12 col-md-6">
								<h5 class="mt-3">1st Emergency Contact</h5>
								<div>
									<label class="form-label" for="emergency_contact1_name">Contact Name</label>
									<input type="text" class="form-control" id="emergency_contact1_name" v-model="employee.emergency_contact1_name" placeholder="eg. First & Last Name" :required="rules.emergency_contact1_name.required">
								</div>
								<div>
									<label class="form-label" for="emergency_contact1_phone">Contact Phone Number</label>
									<input type="text" class="form-control" id="emergency_contact1_phone" v-model="employee.emergency_contact1_phone" placeholder="eg. 4168886666" :required="rules.emergency_contact1_phone.required">
									<p class="text-muted"><small>Phone number is free form, extensions are allowed.</small></p>
								</div>
								<div>
									<label class="form-label" for="emergency_contact1_relationship">Contact Relationship</label>
									<select class="form-select" v-model="employee.emergency_contact1_relationship" :required="rules.emergency_contact1_relationship.required">
										<option>- - select - -</option>
										<option v-for="r,idx in relationship_list" :key="idx" :value="r">{{r}}</option>
									</select>
								</div>
							</div>
							<div class="col-sm-12 col-md-6">
								<h5 class="mt-3">2nd Emergency Contact</h5>
								<div>
									<label class="form-label" for="emergency_contact2_name">Contact Name</label>
									<input type="text" class="form-control" id="emergency_contact2_name" v-model="employee.emergency_contact2_name" placeholder="eg. First & Last Name" :required="rules.emergency_contact2_name.required">
								</div>
								<div>
									<label class="form-label" for="emergency_contact2_phone">Contact Phone Number</label>
									<input type="text" class="form-control" id="emergency_contact2_phone" v-model="employee.emergency_contact2_phone" placeholder="eg. 4168886666" :required="rules.emergency_contact2_phone.required">
									<p class="text-muted"><small>Phone number is free form, extensions are allowed.</small></p>
								</div>
								<div>
									<label class="form-label" for="emergency_contact2_relationship">Contact Relationship</label>
									<select class="form-select" v-model="employee.emergency_contact2_relationship" :required="rules.emergency_contact2_relationship.required">
										<option>- - select - -</option>
										<option v-for="r,idx in relationship_list" :key="idx" :value="r">{{r}}</option>
									</select>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<hr />
			<section class="mt-5">
				<div class="row">
					<div class="col">
						<h3>Work Eligibility</h3>
						<p>Your eligibility to work in <strong>Canada</strong> should be clearly defined. You will be required to provide proof in the form of government issued identification.</p>
						<div class="row">
							<div class="col-sm-12 col-md-6">
								<label class="form-label" for="work_eligibility">My Status is</label>
								<select class="form-select" v-model="employee.work_eligibility" id="work_eligibility" :required="rules.work_eligibility.required">
									<option :value="1">Eligible to Work in Canada</option>
									<option :value="0">Not currently eligible to work in Canada</option>
								</select>
								<div v-if="employee.work_eligibility==1">
									<h5 class="mt-3">Instructions</h5>
									<label class="form-label">Please Upload a Picture of Your Government Issued Identification</label>
									<p><strong>Types of Accepted Documents</strong></p>
									<ul>
										<li>Canadian Passport</li>
										<li>Canadian VISA/Permit</li>
										<li>Canadian Birth Certificate</li>
									</ul>
									<div class="row">
										<div class="col">
											<img src="@/assets/identity/cad-passport.jpg" class="border rounded identity-thumb" />
										</div>
										<div class="col">
											<img src="@/assets/identity/cad-visa.jpg" class="border rounded identity-thumb" />
										</div>
										<div class="col">
											<img src="@/assets/identity/ont-birth.webp" class="border rounded identity-thumb" />
										</div>
									</div>
									<p><strong>Instructions for the Picture</strong></p>
									<ul>
										<li>Please include a photo of the entire document with all four corners visible.</li>
										<li>Make sure that the picture is clear and not blurry.</li>
									</ul>
								</div>
							</div>
							<div class="col-sm-12 col-md-6" v-if="employee.work_eligibility==0">
								<label class="form-label" for="work_eligibility_comments">Please provide details on your status for work in Canada.</label>
								<textarea class="form-control" id="work_eligibility_comments" v-model="employee.work_eligibility_comments" rows="5" :required="rules.work_eligibility_comments.required"></textarea>
							</div>
							<div class="col-sm-12 col-md-6" v-if="employee.work_eligibility==1">
								<h5>Upload your Identification Documents Below..</h5>
								<div>
									<div class="badge bg-success fs-3" v-if="stats.idents>0"><i class="bi bi-check2-circle"></i> Your Identification is On File</div>
									
									<div v-if="stats.idents>0">
										<table class="table">
											<thead>
												<tr>
													<th>Filename</th>
													<th colspan="2">Type</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="d,idx in documents" :key="idx">
													<td>{{d.filename}}</td>
													<td>{{d.document_type_id}}</td>
													<td>
														<button type="button" class="btn btn-dark" @click="delete_document(d.employee_document_id)"><i class="bi bi-trash"></i></button>
													</td>
												</tr>
											</tbody>
										</table>
									</div>

								</div>
								<label class="form-label mt-5" v-if="stats.idents>0">Upload a Different Identification Document</label>
								<label class="form-label mt-5" v-if="stats.idents<=0">Upload Your Proof of Identification</label>
								<CIFileUploader action="/api/employee/upload" input-name="ident_file" :extra-params="{type:'identification',employee_id:employee.employee_id}" @after-upload="after_upload" />
							</div>
						</div>
					</div>
				</div>
			</section>
			<hr />
			<section class="mt-5">
				<div class="row">
					<div class="col">
						<h3>Regulatory Information</h3>
						<p>If your field is regulared by a Regulator Body, we require that you provide your membership or certification number.</p>
					</div>
					<div class="col">
						<div>
							<label class="form-label" for="reg_cert_no">Regulatory Body Membership/Certification No:</label>
							<input type="text" class="form-control" v-model="employee.regulatory_membership_cert" id="reg_cert_no">
						</div>
					</div>
				</div>
			</section>
			<hr />
			<div class="d-flex justify-content-center justify-content-md-end mt-3">
				<router-link type="button" class="btn btn-light mt-1 me-1" to="/"><i class="bi bi-box-arrow-left"></i> Cancel, Go Back</router-link>
				<button type="button" class="btn btn-light mt-1 me-1" @click="save_employee(true)"><i class="bi bi-box-arrow-down"></i> Save &amp; Stay on the Page</button>
				<button type="button" class="btn btn-light mt-1" @click="save_employee(false)"><i class="bi bi-box-arrow-right"></i> Save &amp; Go Back</button>
			</div>
			
		</form>
	</div>

</template>
<script>
	import CIFileUploader from '@/components/CIFileUploader.vue'
	export default{
		Name: 'NewHireInformationView',
		components: {CIFileUploader},
		data(){return{
			userinfo: {},
			employee: {
				employee_id: null,
				gender: null,
				police_record_check_status: null,
			},
			documents: [],
			employee_loaded: false,
			sin_number: {one:'',two:'',three:''},
			alt_gender_toggle: false,
			relationship_list: ['Spouse','Wife','Husband','Father','Mother','Brother','Sister','In-law','Friend','Colleague','Sibling','Other'],
			provinces: ['Ontario','Quebec','British Columbia','Alberta','Manitoba','Saskatchewan','Nova Scotia','New Brunswick','Newfoundland and Labrador','Prince Edward Island','Northwest Territories','Yukon','Nunavut'],
			stats: {
				idents: 0,
				police: 0,
			},
			show_ident_upload: false,
			rules: {
				given_name: {msg:'Given Name is Required', required: true, rule: 'required'},
				last_name: {msg:'Last Name is Required', required: true, rule: 'required'},
				sin_number: {msg:'SIN Number is Required', required: true, rule: 'required'},
				birth: {msg:'Birth Date is Required', required: true, rule: 'required'},
				gender: {msg:'Gender is Required', required: true, rule: 'required'},
				primary_contact_phone: {msg:'Your Primary Phone is Required', required: true, rule: 'required'},
				primary_contact_email: {msg:'Your Primary Email is Required', required: true, rule: 'required'},
				street: {msg:'Street Address is Required', required: true, rule: 'required'},
				city: {msg:'City/Town is Required', required: true, rule: 'required'},
				province: {msg:'Province is Required', required: true, rule: 'required'},
				postal_code: {msg:'Postal Code is Required', required: true, rule: 'required'},
				emergency_contact1_name: {msg:'Emergency Contact 1 Name is Required', required: true, rule: 'required'},
				emergency_contact1_phone: {msg:'Emergency Contact 1 Phone is Required', required: true, rule: 'required'},
				emergency_contact1_relationship: {msg:'Emergency Contact 1 Relationship is Required', required: true, rule: 'required'},
				emergency_contact2_name: {msg:'Emergency Contact 2 Name is Required', required: true, rule: 'required'},
				emergency_contact2_phone: {msg:'Emergency Contact 2 Phone is Required', required: true, rule: 'required'},
				emergency_contact2_relationship: {msg:'Emergency Contact 2 Relationship is Required', required: true, rule: 'required'},
				work_eligibility: {msg:'Your Work Eligibility is Required, You must Provide an Uploaded Identification Document', required: true, rule: 'eligibility'},
				work_eligibility_comments: {msg:'Please explain your work eligibility selection', required: true, rule: 'eligibility_comments'},
			},
			save_count: 0,
		}},
		computed: {
			warnings(){
				let v = this
				let warnings = []
				for(let key in v.rules)
				{
					let r = v.rules[key]
					if( r.required )
						warnings.push(r.msg)
				}
				return warnings
			},	
		},
		watch: {
			'sin_number.one': function(){
				this.set_sin_number();
			},
			'sin_number.two': function(){
				this.set_sin_number();
			},
			'sin_number.three': function(){
				this.set_sin_number();
			},
			'employee.gender': function(newvalue){
				let v = this
				if(newvalue=='other')
				{
					v.alt_gender_toggle = true
					v.employee.gender = ''
				}
			},
		},
		methods: {
			load_employee(employee_id){
				// Loads the Employee Record
				let v = this
				if( employee_id === 0 )
				{
					v.employee = {
						employee_id: 0,
						given_name: null,
						middle_name: null,
						last_name: null,
						preferred_name: null,
						previous_last_name: null,
						sin_number: null,
						birth: null,
						gender: null,
						primary_contact_phone: null,
						primary_contact_email: null,
						street: null,
						city: null,
						province: null,
						postal_code: null,
						emergency_contact1_name: null,
						emergency_contact1_phone: null,
						emergency_contact1_relationship: null,
						emergency_contact2_name: null,
						emergency_contact2_phone: null,
						emergency_contact2_relationship: null,
						regulatory_membership_cert: null,
						work_eligibility: null,
						work_eligibility_comments: null,
						french_language_level: null,
						police_record_check_status: null,
					}
					v.employee_loaded = true
				}
				else
				{
					// NOTE: the logic below is horrible but I was in a hurry but it works so sue me
					if( employee_id > 0)
					{
						// Nothing, employee_id is good
					}
					else if(!employee_id && v.employee.employee_id > 0 )
					{
						employee_id = v.employee.employee_id
					}
					else
					{
						v.$alert('There has been an error loading an employee record.',{icon:'error'})
						return false
					}

					v.axios.get('/api/employee/'+String(employee_id)).then(response=>{
						v.employee = response.data.employee
						v.stats = response.data.stats
						v.validate_form()
						v.get_sin_number()
						v.employee_loaded = true
						v.load_documents()
					})
				}
			},
			load_documents(){
				const v = this
				let payload = {
					type: 'identification',
				}
				v.axios.get('api/employee/documents/'+String(v.employee.employee_id),{params:payload}).then(response=>{
					v.documents = response.data
				}).catch(err=>{
					console.error('ERROR RETRIEVING DOCUMENTS: ',err.response.data)
					if( err.response.data.messages.error )
						v.$alert(err.response.data.messages.error)
				})
			},
			delete_document(employee_document_id){
				let v = this

				v.$confirm({
					text:'Are you sure you want to remove this identification document?',
					success(){
						v.axios.delete('api/employee/document/'+String(employee_document_id)).then(response=>{
							v.$toast('Identification Document Removed','success')
							v.load_employee()
						})
					}
				})
			},
			save_employee(stay_on_page=true){
				const v = this

				v.axios.post('/api/employee',v.employee).then(response=>{
					v.$toast('Information Saved','success');
					v.employee = response.data.employee
					v.stats = response.data.stats
					v.validate_form()
					v.save_count++
					if( !stay_on_page )
						v.$router.push('/')
				})
				
			},
			// Uses the split fields values in sin_number to construct the SIN number in the employee.sin_number field
			set_sin_number(){
				let v = this; let p1 = ''; let p2 = ''; let p3 = '';
				if( v.sin_number.one )
					p1 = v.sin_number.one
				if( v.sin_number.two)
					p2 = v.sin_number.two
				if( v.sin_number.three )
					p3 = v.sin_number.three
				v.employee.sin_number = [p1,p2,p3].join('-')
			},
			// Retrieves and splits the sin number value from the employee.sin_number field into component parts for display
			get_sin_number(){
				let v = this
				if( v.employee.sin_number == null )
				{
					v.sin_number.one = ''
					v.sin_number.two = ''
					v.sin_number.three = ''
				}
				else
				{
					let sin = String(v.employee.sin_number).split('-')
					if( sin[0] && sin[0] )
						v.sin_number.one = sin[0]
					if( sin[1] && sin[1] )
						v.sin_number.two = sin[1]
					if( sin[2] && sin[2] )
						v.sin_number.three = sin[2]
				}
			},
			after_upload(){
				const v = this
				v.save_employee(true)
				v.load_employee()
			},
			validate_form(){
				let v = this
				for(let key in v.rules)
				{
					let rule = v.rules[key]
					let func = eval('this.validate_'+rule.rule)
					v.rules[key].required = !func(v.employee[key])
				}
			},
			validate_required(input){
				return !!input
			},
			validate_eligibility(input){
				let v = this
				if( input == 1 && v.stats.idents > 0 )
					return true
				if( input == 0 )
					return true
				return false
			},
			validate_eligibility_comments(input){
				if( this.employee.work_eligibility == 0 )
					return !!input
				if( this.employee.work_eligibility == 1 )
					return true
				return false
			},
		},
		mounted(){
			let v = this

			v.userinfo = v.get_token_info()

			let id = v.$route.params.id

			if( id )
			{
				v.load_employee(id)
				return
			}

			if( v.userinfo.id )
				v.load_employee(v.userinfo.id)
			else
				v.load_employee(0)
		},
	}
</script> 
