<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-sm-12 col-md-4 d-flex align-items-center justify-content-center">
				<router-link to="/"><img src="@/assets/NHH_Full_Logo.png" style="width:300px;" class="mt-4 mb-4" /></router-link>
			</div>
			<div class="col-sm-12 col-md-3 d-flex align-items-center justify-content-center">
				<div class="d-none d-sm-block">
					<h1 class="m-0 p-0" id="top">Orientation Portal</h1>
					<p class="text-muted m-0 p-0">Integrity &middot; Quality &middot; Respect &middot; Teamwork &middot; Compassion</p>
				</div>
			</div>
			<div class="col-sm-12 d-flex align-items-center justify-content-center d-sm-none bg-light">
				<h2 class="mt-1 mb-2 p-0"><small>Orientation Portal</small></h2>
			</div>
			<div class="d-flex align-items-center justify-content-center pt-3 pb-3" :class="block2_class">
				<div>
					<div><i class="bi bi-telephone-fill text-primary"></i>&nbsp;Local: <a href="tel:19053726811" class="text-primary" onclick="ga('send', 'event', {eventCategory: 'Phonecall', eventAction: 'Click'});">905-372-6811 ext 4040</a></div>
					<div><i class="bi bi-envelope-fill text-primary"></i>&nbsp;Email: <a href="mailto:askhr@nhh.ca" class="text-primary">askhr@nhh.ca</a></div>
					<div><i class="bi bi-geo-alt-fill text-primary"></i>&nbsp;<a href="/AboutNHH/Location#map" class="text-primary" title="Location">1000 DePalma Dr, Cobourg ON</a></div>
				</div>
			</div>
			<div class="col" v-if="logged_in">
				<div class="d-flex h-100 align-items-center justify-content-center">
					<div class="w-100 mt-3 mb-3">
						Welcome {{userinfo.fullname}}&nbsp;
						<button type="button" class="btn btn-light btn-sm ms-1" @click="logout">Logout</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default{
		name: 'MastHead',
		data(){return{
			userinfo: {fullname: null}
		}},
		computed: {
			logged_in(){
				let v = this
				v.userinfo = v.get_token_info()
				return (v.get_token() != undefined)
			},
			block2_class(){
				let v = this
				return {
					col: !v.logged_in,
					'col-sm-12': v.logged_in,
					'col-md-3': v.logged_in,
				}
			},
		},
		methods: {
			logout(){
				let v = this
				v.kill_token()
				v.$nextTick(function(){
					window.location.href = '/'; // Send the User Home witha  refresh
				});
			},
		},
		mounted(){
		},
	}
</script> 
