<template>
	<div class="border rounded p-5 mt-5 mx-auto bg-td-section-gray" style="max-width: 600px;box-shadow: 3px 3px #919191;">
		<h1>Email Verification</h1>
		<h5>Verify Your Email Account</h5>
		<hr />
		<p>For security reasons you are required to verify your email account each time you login with a secure code that we have sent to your email address.</p>
		<p>Check your mail for the code and enter it in the box below to continue logging in.</p>
		<p><strong>Do Not close the browser or Reload the Page</strong> or you will have to start over.</p>
		<div>
			<div class="mb-3">
				<label for="otp" class="form-label">One-Time Code</label>
				<input type="text" class="form-control text-center" id="otp" v-model="otp" @keyup="submitme">
			</div>
			<div class="mt-2 mb-2" v-if="msg_fail || msg_success">
				<div class="badge text-bg-danger" v-if="msg_fail">{{msg_fail}}</div>
				<div class="badge text-bg-success" v-if="msg_success">{{msg_success}}</div>
			</div>
			<div class="mb-3 text-end">
				<button type="button" class="btn btn-primary" @click="verify">Verify One-Time Code</button>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	Name: 'LoginForm',
	data(){return{
		otp: null,
		msg_fail: null,
		msg_success: null,
	}},
	methods: {
		submitme(e){
			if( e.key === 'Enter' || e.keyCode === 13 )
				this.verify()
		},
		verify(){
			const v = this
			let payload = {
				otp: v.otp,
			}
			v.msg_fail = null
			v.msg_success = null
			v.axios.post('/api/otp/verify',payload).then(response=>{
				if( response.data.verified == true )
				{
					v.msg_success = "OTP Verified"
					v.$emit('verified')
				}
				if( response.data.verified == false )
				{
					v.msg_fail = "Verification Failed, please check your code and try again."
				}
			}).catch(error=>{
				if( error.response.data )
				{
					v.msg_fail = error.response.data.messages.error
				}
			})
		},
	},
}
</script> 
