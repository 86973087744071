<template>
	<div class="container mt-3 mh-65">
		<h2>Certificates</h2>
		<p>Please upload copies of your education and qualification certificates and credentials relevant to your role as required for your employment.</p>
		<hr />
		<div class="d-flex justify-content-end">
			<button type="button" class="btn btn-light" @click="$router.go(-1)">Back to Dashboard</button>
		</div>
		<hr />
		<p class="text-center text-muted" v-if="certs.length<=0">You have not uploaded any certificates.</p>
		<div v-if="certs.length>0">
			<table class="table">
				<thead>
					<tr>
						<th>Filename</th>
						<th colspan="2">Type</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="c in certs" :key="c.employee_document_id">
						<td>{{c.filename}}</td>
						<td>{{c.document_type_id}}</td>
						<td>
							<button type="button" class="btn btn-dark" @click="delete_document(c.employee_document_id)"><i class="bi bi-trash"></i></button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<hr />
		<h3 class="fs-5">Instructions:</h3>
		<ul>
			<li>You may upload PDF files, DOC files, or DOCX files.</li>
			<li>You may upload images such as JPEG's, JPG, GIF, PNG, or even TIFF files.</li>
		</ul>
		<div class="mb-5">
			<label class="form-label" for="cert-upload">Upload one or many files at a time to your Certificate List</label>
			<CIFileUploader action='api/employee/upload' input-name="qual_file" id="cert-upload" multiple="true" :extra-params="{type:'qualification'}" @after-upload="load_documents" />
		</div>
		<div class="d-flex justify-content-center mb-5">
			<button type="button" class="btn btn-light" @click="$router.go(-1)">Back to Dashboard</button>
		</div>
	</div>
</template>
<script>
	import CIFileUploader from '@/components/CIFileUploader.vue'
	export default { 
		name: 'CertificatesView',
		components: {CIFileUploader},
		data(){return{
			certs: [],
			userinfo: {},
		}},
		methods: {
			/** 
			 * Load certificate files from the server as a list
			 */
			load_documents(){
				let v = this
				v.axios.get('api/certs').then(response=>{
					v.certs = response.data
				})
			},
			delete_document(employee_document_id){
				let v = this

				v.$confirm({
					text:'Are you sure you want to remove this qualification document?',
					success(){
						v.axios.delete('api/employee/document/'+String(employee_document_id)).then(response=>{
							v.$toast('Document Removed','success')
							v.load_documents()
						})
					}
				})
			},
		},
		mounted(){
			this.load_documents()
		}
	}
</script>
