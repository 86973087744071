<style scoped>
	.view{
		margin-bottom: 300px;
		margin-top: 0px;
	}
</style>
<template>
	<div class="bg-white view"> <!--add fullscreen class to make it be a fullscreen container-->
		<div class="row">
			<div class="col">
				<LoginForm @login="on_login" />
			</div>
		</div>
	</div>
</template>
<script>
	import LoginForm from '@/components/LoginForm.vue'
	export default{
		Name: "LoginView",
		components: {LoginForm},
		data(){return{
			token: null,
		}},
		methods: {
			on_login(token,skip_otp=false){
				const v = this
				v.token = token
				// Setting the token is performed here by the loginform for admins only, it's performed in App.vue for logins by code/employees
				v.set_token(v.token)
				if( skip_otp )
				{
					let info = v.get_token_info()
					if( info.is_admin )
						window.location = '/admin'
					else
						window.location = '/'
				}
				else
					window.location = '/login/otp'
			},
		},
	}
</script> 
