<style scoped>
	.view{
		margin-bottom: 300px;
		margin-top: 0px;
	}
</style>
<template>
	<div class="bg-white view"> <!--add fullscreen class to make it be a fullscreen container-->
		<div class="row">
			<div class="col">
				<div class="text-center">
					<div class="spinner-border text-blue mt-5" role="status">
						<span class="visually-hidden">Loading...</span>
					</div>
					<div>Logging in...</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default{
		Name: "LoginCodeView",
		data(){return{
		}},
		methods: {
		},
	}
</script> 
