import Cookies from 'js-cookie'
import router from './router'

/**
 * Included for convenience elsewhere, decodes JWT's to JSON data
 * @param	token	string			Encoded Javascript Web Token
 */
function parse_token (token) {
		var base64Url = token.split('.')[1];
		// if( base64Url == undefined ) return false; // DEBUGGING 
		var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
		}).join(''));
		return JSON.parse(jsonPayload);
}

/**
 * Initializes the settings for the ajax object
 * @param token		string			included as the bearer token if present, ignored otherwise
 */
function init_axios_instance(token){
	let ajax = {}
	let headers = {
		'X-Requested-With': 'XMLHttpRequest'
	}
	if(token)
		headers['Authorization'] = 'Bearer '+token
	if( process.env.VUE_APP_API_BASE_URL != undefined )
		ajax = axios.create({
			baseURL: process.env.VUE_APP_API_BASE_URL, //https://api.orientation.nhh.com/
			timeout: 5000,
			headers: headers,
			withCredentials: true,
		})
	else
	{
		console.log('.env.<environment> file missing, default server config loaded')
		ajax = axios.create({
			baseURL: 'http://localhost:8080',
			timeout: 5000,
			headers: headers,
			withCredentials: true,
		})
	}
	return ajax
}

function intercept_login_admin(ajax)
{
	// Add a response interceptor - Redirects user IMMEDIATELY upon detection of auth failure to the login page
	ajax.interceptors.response.use(
		response => response, 
		error => {
			// Any status codes that falls outside the range of 2xx cause this function to trigger
			// Do something with response error
			switch(error.response.status)
			{
				case 433:
					window.location = '/login/otp'
					break
				case 401:
					window.alert('Access Denied: Your session may have expired, please logout/login and try again')
					Cookies.remove(process.env.VUE_APP_API_BASE_URL)
					router.push('/login')
					break
			}
			if(error.response.status >= 500)
			{
				window.alert('Error '+error.response.status+': '+error.response.data.message);
				Cookies.remove(process.env.VUE_APP_API_BASE_URL)
				router.push('/login')
			}
			return Promise.reject(error);
		}
	)//end interceptor
}

/**
 * Decodes the JWT and provides the user info directly in a single command
 */
function get_token_info()
{
	let token = get_token()
	let data = {}
	if( token != undefined )
		data = parse_token(token)
	return data
}

/** 
 * Sets the token in the cookie
 */
function set_token(token)
{
	Cookies.set(process.env.VUE_APP_JWT_COOKIE_NAME,token)
}

/**
 * Retrieves the token from the cookie
 */
function get_token(){
	return Cookies.get(process.env.VUE_APP_JWT_COOKIE_NAME)
}

/**
 * Kills the Token Cookie
 */
function kill_token(){
	Cookies.remove(process.env.VUE_APP_JWT_COOKIE_NAME)
}

const axios = require('axios')

let token = get_token()

let ajax = init_axios_instance(token)

intercept_login_admin(ajax)

export {ajax,kill_token,parse_token,get_token,get_token_info,set_token}
