<style scoped>
	.file-input{
	}
</style>
<template>
	<div class="ci-file-uploader">
		<div v-show="uploading">
			<img src="@/assets/loading_icon.gif" />
		</div>

		<div v-show="!uploading">
			<div class="mb-2" v-for="file,idx in files" :key="idx" v-show="files.length">
				<div class="row">
					<div class="col-sm-12 col-md-4">
						<small>File:</small>		
					</div>
					<div class="col-sm-12 col-md-8">
						<strong>{{file.name}}</strong>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-12 col-md-4">
						<small>Size:</small>		
					</div>
					<div class="col-sm-12 col-md-8">
						{{file.size}} kb
					</div>
				</div>
				<hr />
			</div>
			<div class="text-end" v-show="files.length">
				<button type="button" class="btn btn-light mt-1" @click="cancel_files">Cancel</button>
				<button type="button" class="btn btn-primary mt-1 ms-1" @click="submit_files">Upload File(s)</button>
			</div>
			<div v-show="files.length==0">
				<input type="file" :id="'file-input-'+inputName" :accept="accept_list" class="form-control file-input" @change="add_upload_file" ref="file" :multiple="multiple">
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		// Codeigniter Compatible File Uploader
		// Corresponding Methods in the FileUploader controller in CodeIgniter
		name: 'CIFileUploader',
		props: ['action','inputName','multiple','afterUpload','extraParams','accept'],
		data(){return{
			uploading: false,
			files: [],
		}},
		computed: {
			accept_list(){
				const v = this
				if( v.accept )
					// Whatever is provided as a property
					return v.accept
				else
					// Default List
					return ".doc,.docx,.rtf,.odt,.xls,.ods,.xlsx,.ppt,.pptx,.pdf,.txt,.csv,.jpg,.gif,.jpeg,.png,.heic,.webp";
			},
		},
		methods: {
			add_upload_file() {
				const v = this
				v.files = v.$refs.file.files
			},
			cancel_files(){
				const v = this
				v.$refs.file.value = ''
				v.files = []
			},
			submit_files(){
				const v = this
				const input_name = v.inputName ? v.inputName : 'file' // Name of the Input
				v.uploading = true
				const formData = new FormData()
				for(let f of v.files)
					formData.append(input_name+'[]', f)
				if( v.extraParams )
					for(let p in v.extraParams)
						formData.append(p,v.extraParams[p])
				const headers = { 'Content-Type': 'multipart/form-data' };
				v.axios.post(v.action, formData, { headers }).then(response=>{
					v.files = []
					v.$refs.file.value = '';
					v.uploading = false
					v.$emit('afterUpload',true)
				}).catch(error=>{
					v.$emit('afterUpload',false)
					console.error('UPLOAD ERROR: ',error)
				})
			},
		},
	}
</script> 
