<template>
	<div class="w-100 p-5 bg-primary text-center text-white" style="bottom: 0px; left:0px;">
		Copyright &copy; {{curdate()}}. Northumberland Hills Hospital. All Rights Reserved.
	</div>
</template>
<script>
	export default{
		name: 'FooterBanner',
		methods: {
			curdate(){
				let dt = new Date;
				return dt.getFullYear();
			},
		}
	}
</script> 
