<template>
	<div class="bg-dark d-flex justify-content-center mb-5">
		<img src="@/assets/masthead-banner.png" class="flex-grow-1 d-none d-sm-block" v-if="display=='home'" />
	</div>
</template>
<script>
	export default{
		name: 'MastHeadBanner',
		data(){return{
			display: 'home',
		}},
		watch: {
			'$route': function(to,from){
				let v = this
				if( to.path == '/' || to.path == '/login' || to.name == 'employee_login' )
					v.display = 'home'
				else
					v.display = 'inside'
			},
		},
		mounted(){
		},
	}
</script> 
