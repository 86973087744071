<template>
	<div class="container mh-65">
		<h2>WHMIS</h2>
	</div>
</template>
<script>
	export default{
		name: 'WHMISView',
	}
</script> 
