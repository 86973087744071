<template>
	<div class="container mh-65">
		<h2>Employee Admin</h2>

		<div class="mt-4 mb-2">
			<h5>Filters</h5>
			<form @submit.prevent="null">
				<div class="row">
					<div class="col-sm-12 col-md-4">
						<div class="input-group">
							<input type="text" class="form-control" placeholder="Search by keyword.." role="search" v-model="search_keyword">
							<button type="button" class="btn btn-outline-secondary" aria-label="Search reset button, clears the search criteria." @click="search_keyword=''">
								<i class="bi bi-x"></i>
							</button>
						</div>
					</div>
					<div class="col-sm-12 col-md-8 text-end">
						<router-link class="btn btn-primary" to="/admin/employee"><i class="bi bi-plus"></i> Add New Employee</router-link>
					</div>
				</div>
			</form>
		</div>

		<table class="table">
			<thead>
				<tr>
					<th>Employee Name</th>
					<th>Email</th>
					<th>Progress</th>
					<th>Status</th>
					<th colspan="2">Last Login</th>
				</tr>
			</thead>
			<tbody v-if="employees_loaded">
				<tr v-for="e,idx in employees" :key="idx">
					<td>{{e.full_name}}</td>
					<td>{{e.primary_contact_email}}</td>
					<td class="text-center">{{e.complete_percent}} %</td>
					<td>
						<div class="badge bg-success ms-1" v-if="e.suspend=='0'"><i class="bi bi-check-circle"></i> Active</div>
						<div class="badge bg-dark ms-1" v-if="e.suspend=='1'"><i class="bi bi-x"></i> Suspended</div>
						<div class="badge bg-danger ms-1" v-if="e.locked=='1'"><i class="bi bi-lock"></i> Locked</div>
					</td>
					<td>{{format_date(e.last_login)}}</td>
					<td>
						<router-link class="btn btn-sm btn-secondary" :to="'/admin/employee/'+String(e.employee_id)">Profile</router-link>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
	export default{
		name: 'EmployeeAdminView',
		data(){return{
			employees_loaded: false,
			employees: [],
			search_keyword: '',
			search_timeout: null,
		}},
		watch: {
			search_keyword(){
				let v = this
				if(v.search_timeout)
					clearTimeout(v.search_timeout)
				v.search_timeout = setTimeout(function(){
					v.load_employees()
				},300)
			},
		},
		methods: {
			full_name(employee){
				let name = ''
				if( employee.given_name )
					name += employee.given_name
				if( employee.middle_name )
					name += ' ('+employee.middle_name+')'
				if( employee.last_name )
					name += ' '+employee.last_name
				return name
			},
			load_employees(){
				let v = this
				v.axios.get('/api/admin/employees?filter='+String(v.search_keyword)).then(response=>{
					v.employees = response.data
					
					for (let i = 0; i < v.employees.length; i++)
					{
						let employee = v.employees[i]

						employee.full_name = v.full_name(employee)
					}

					v.employees_loaded = true
				})
			},
			format_date(date){
				if( !date )
					return '---'
				let d = new Date(date)
				return d.toLocaleString()
			}
		},
		mounted(){
			let v = this
			let userinfo = v.get_token_info()
			if( !userinfo.is_admin )
				v.$router.push('/')
			v.load_employees()
		}
	}
</script> 
