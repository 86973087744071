<template>
	<div class="container mt-3 mh-65">
		<h2>Edit Reference Document</h2>
		<p>Change the title or description associated with a reference document.</p>
		<div>
			<label class="form-label" for="title">Title</label>
			<input type="text" class="form-control" v-model="document.title" id="title" required>
		</div>
		<div>
			<label class="form-label" for="created">Created</label>
			<input type="text" class="form-control-plaintext" v-model="document.created" readonly>
		</div>
		<div>
			<label class="form-label" for="filename">File Name</label>
			<input type="text" class="form-control-plaintext" v-model="document.filename" readonly>
		</div>
		<div class="d-flex flex-row-reverse">
			<button type="button" class="btn btn-primary ms-1" @click="save"><i class="bi bi-check-circle"></i> Save Changes</button>
			<button type="button" class="btn btn-light ms-1" @click="cancel"><i class="bi bi-box-arrow-left"></i> Cancel</button>
		</div>
	</div>
</template>
<script>
	export default{
		name: 'RefDocsAdminEditView',
		data(){return{
			document: {
				title: null,
				created: null,
				filename: null,
			},
		}},
		methods: {
			load_document(reference_document_id){
				let v = this
				v.axios.get('api/document/'+String(reference_document_id)).then(response=>{
					v.document = response.data
				})
			},
			save(){
				let v = this
				v.axios.post('api/document',v.document).then(response=>{
					v.$toast('Changes Saved','success')
					v.$router.go(-1)
				})
			},
			cancel(){
				this.$router.go(-1)
			}
		},
		mounted(){
			let v = this
			v.load_document(v.$route.params.id)
		},
	}
</script> 
