<template>
	<div class="container mh-65" id="top">
		<h2>Invite a New Employee/Candidate</h2>
		<p>In order to invite a new employee to access the portal, you'll need to provide the most basic information about the candidate. <strong>All Fields Are Required!</strong></p>
		<fieldset>
			<legend>Required Invitation Information</legend>
			<form @submit.prevent="send_invite">
				<div class="row">
					<div class="col">
						<label class="form-label" for="given_name">First Name</label>
						<input class="form-control" id="given_name" v-model="employee.given_name" :required="rules.given_name.required">
					</div>
					<div class="col">
						<label class="form-label" for="last_name">Last Name</label>
						<input class="form-control" id="last_name" v-model="employee.last_name" :required="rules.last_name.required">
					</div>
				</div>
				<div class="row">
					<div class="col">
						<label class="form-label" for="primary_contact_email">Email Address</label>
						<input class="form-control" id="primary_contact_email" v-model="employee.primary_contact_email" :required="rules.primary_contact_email.required">
						<div class="alert alert-danger" v-show="msg">{{msg}}</div>
					</div>
				</div>
			</form>
		</fieldset>
		<hr />
		<div class="position-relative d-flex justify-content-end">
			<button type="button" class="btn btn-secondary mt-1 me-1" @click="$router.go(-1)">Cancel, Go Back</button>
			<button type="button" class="btn btn-primary mt-1 me-1" @click="send_invite"><i class="bi bi-envelope"></i> Send Invitation</button>
		</div>
	</div>
</template>
<script>
	export default{
		name: 'EmployeeNewView',
		data(){return{
			employee: {
				given_name: null,
				last_name: null,
				primary_contact_email: null,
			},
			rules: {
				given_name: {msg:'Given Name is Required', required: true, rule: 'required'},
				last_name: {msg:'Last Name is Required', required: true, rule: 'required'},
				primary_contact_email: {msg:'Email Address is Required', required: true, rule: 'email'},
			},
			msg: null, //error message displayed after failed attemptss
		}},
		watch: {
			'employee.given_name': function(){
				this.validate_form()
			},
			'employee.last_name': function(){
				this.validate_form()
			},
			'employee.primary_contact_email': function(){
				this.validate_form()
			},
		},
		methods:{
			validate_form(){
				let v = this
				let result = true
				for(let key in v.rules)
				{
					let rule = v.rules[key]
					let func = eval('this.validate_'+rule.rule)
					v.rules[key].required = !func(v.employee[key])
					if( v.rules[key].required )
						result = false
				}
				return result
			},
			validate_required(input){
				return !!input
			},
			validate_email(input){
				var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
				if(!input) return false
				return input.match(validRegex)
			},
			send_invite(){
				let v = this
				if( !v.validate_form() )
				{
					v.$toast('Required information is missing or invalid, check your form.','error')
					return
				}
				else
				{
					v.axios.post('api/admin/employee/invite',v.employee).then(response=>{
						v.$toast('Sending Invitation','success')
						v.$router.go(-1)
					}).catch(error=>{
						if( error.response.data.msg )
							v.msg = error.response.data.msg
					})
				}
			},
		},
		mounted(){
			let v = this
			v.validate_form()
		}
	}
</script> 
