<template>
	<MastHead />
	<AdminNavigation />
	<MastHeadBanner />
	<router-view></router-view>
	<FooterBanner />
</template>
<script>
	import AdminNavigation from "@/components/AdminNavigation.vue"
	import MastHead from "@/components/MastHead.vue"
	import MastHeadBanner from "@/components/MastHeadBanner.vue"
	import FooterBanner from "@/components/FooterBanner.vue"
	export default {
		name: 'App',
		components: {AdminNavigation,MastHead,MastHeadBanner,FooterBanner},
		data(){return{
			logged_in: false,
			admin: true,
		}},
		methods: {
			logout(){
				let v = this
				v.logged_in = false
				v.kill_token()
				v.$router.push('/login')
			},
			login_by_code(access_code){
				// sends login submission to the server - only intended for employee login
				let v = this
				console.log('login_by_code('+access_code+')')
				v.axios.post('api/authcode',{access_code:access_code}).then(response=>{
					if( !response )
					{
						v.$toast('Access Code Invalid, Try again or contact an administrator for help.','error')
						return
					}
					if(response.data.token)
					{
						v.set_token(response.data.token)
						// this method of redirect is required to refresh the session after setting the cookie
						window.location.href = '/login/otp'
					}
				}).catch(error=>{
					v.$toast('Access Code Invalid, Try again or contact an administrator for help.','error')
					// console.log(error)
				})
			},
		},
		mounted(){
			let v = this
			document.title = 'NHH Orientation Portal'
			if( window.location.pathname.match(/\/login\/otp/g) )
			{
				// Do Nothing
			}
			else if( window.location.pathname.match(/\/login\/.*/g) )
			{
				let parts = window.location.pathname.split('/')
				let code = parts[parts.length-1]
				v.logged_in = false
				v.login_by_code(code)
				return
			}
			if(!v.cookies.get(process.env.VUE_APP_JWT_COOKIE_NAME))
			{
				v.logged_in = false
				if(!window.location.pathname.match(/\/login/g))
					v.$router.push('/login')
			}
			else
			{
				v.logged_in = true
			}
		},
	}
</script>
