import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'

import LoginView from './components/views/LoginView.vue'
import LoginOtpView from './components/views/LoginOtpView.vue'
import LoginCodeView from './components/views/LoginCodeView.vue'
import EmployeeDashboardView from './components/views/EmployeeDashboardView.vue'
import PasswordResetView from './components/views/PasswordResetView.vue'
import NewHireInformationView from './components/views/NewHireInformationView.vue'
import AdminDashboardView from './components/views/AdminDashboardView.vue'
import EmployeeAdminView from './components/views/EmployeeAdminView.vue'
import UserAdminView from './components/views/UserAdminView.vue'
import UserAdminEditView from './components/views/UserAdminEditView.vue'
import RefDocsAdminView from './components/views/RefDocsAdminView.vue'
import RefDocsAdminEditView from './components/views/RefDocsAdminEditView.vue'
import CertificatesView from './components/views/CertificatesView.vue'
import HealthAndSafetyView from './components/views/HealthAndSafetyView.vue'
import WHMISView from './components/views/WHMISView.vue'
import AttestationView from './components/views/AttestationView.vue'
import ConfidentialityView from './components/views/ConfidentialityView.vue'
import ValuesView from './components/views/ValuesView.vue'
import TD1FormsView from './components/views/TD1FormsView.vue'
import PoliceCheckView from './components/views/PoliceCheckView.vue'
import FrenchLanguageView from './components/views/FrenchLanguageView.vue'
import EmployeeNewView from './components/views/EmployeeNewView.vue'
import EmployeeProfileView from './components/views/EmployeeProfileView.vue'
import ParkingView from './components/views/ParkingView.vue'
import DirectDepositView from './components/views/DirectDepositView.vue'
import FoundationLotteryView from './components/views/FoundationLotteryView.vue'
import HoopAuthorizationView from './components/views/HoopAuthorizationView.vue'
import ApiDebuggingView from './components/views/ApiDebuggingView.vue'

const routes = [
	{ path: '/',                  component: EmployeeDashboardView },
	{ path: '/login',             component: LoginView },
	{ path: '/login/otp',         component: LoginOtpView },
	{ path: '/login/:code',       component: LoginCodeView },
	{ path: '/password/reset',    component: PasswordResetView },
	{ path: '/employee',          component: NewHireInformationView },
	{ path: '/employee/:id',      component: NewHireInformationView },
	{ path: '/policecheck',       component: PoliceCheckView },
	{ path: '/french',            component: FrenchLanguageView },
	{ path: '/certificates',      component: CertificatesView },
	{ path: '/healthandsafety',   component: HealthAndSafetyView },
	{ path: '/attestation',       component: AttestationView },
	{ path: '/whmis',             component: WHMISView },
	{ path: '/confidentiality',   component: ConfidentialityView },
	{ path: '/values',            component: ValuesView },
	{ path: '/td1forms',          component: TD1FormsView },
	{ path: '/parking',           component: ParkingView },
	{ path: '/directdeposit',     component: DirectDepositView },
	{ path: '/foundationlottery', component: FoundationLotteryView},
	{ path: '/hoopauth',          component: HoopAuthorizationView},
	{ path: '/admin',             component: AdminDashboardView },
	{ path: '/admin/employees',   component: EmployeeAdminView },
	{ path: '/admin/employee/:id',component: EmployeeProfileView },
	{ path: '/admin/employee',    component: EmployeeNewView },
	{ path: '/admin/users',       component: UserAdminView },
	{ path: '/admin/user',        component: UserAdminEditView },
	{ path: '/admin/user/:id',    component: UserAdminEditView },
	{ path: '/admin/docs',        component: RefDocsAdminView },
	{ path: '/admin/doc/:id',     component: RefDocsAdminEditView },
	{ path: '/debug',		 	  component: ApiDebuggingView },
]

const router = createRouter({
	// history: createWebHashHistory('/'),
	history: createWebHistory('/'),
	routes,
	scrollBehavior(to, from, savedPosition) {
		return {top: 0}
		// if (savedPosition) {
		// 	return savedPosition
		// } else {
		// 	return { top: 0 }
		// }
	},
})

export default router
 
