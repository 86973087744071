<style scoped>
	.view{
		margin-bottom: 300px;
		margin-top: 0px;
	}
</style>
<template>
	<div class="bg-white view"> <!--add fullscreen class to make it be a fullscreen container-->
		<div class="row">
			<div class="col">
				<OtpForm @verified="on_verify" />
			</div>
		</div>
	</div>
</template>
<script>
	import OtpForm from '@/components/OtpForm.vue'
	export default{
		Name: "LoginOtpView",
		components: {OtpForm},
		data(){return{
			token: null,
		}},
		methods: {
			on_verify(){
				const v = this
				let info = v.get_token_info()
				// this method of redirect is required to refresh the session after setting the cookie
				if( info.is_admin )
					window.location = '/admin'
				else
					window.location = '/'
			},
		},
	}
</script> 
