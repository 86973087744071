<style scoped>
	a.nav-link{
		color: #fff;
	}
	a.nav-link:hover{
		color: #333333;
	}
	a.nav-link .active{
		color: #555555;
	}
	.navbar-toggler-icon{
		color: #fff;
	}
	.topcell{
		display: block;
		height: 8px;
		width: 100%;
		margin: 0px;
		padding: 0px;
	}
</style>
<template>
	<div class="bg-primary p-4" v-if="!is_admin">
	</div>
	<nav class="navbar navbar-expand-lg bg-body-tertiary bg-primary" data-bs-theme="dark" v-if="is_admin">
		<div class="container-fluid">
			<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>
			<div class="collapse navbar-collapse" id="navbarSupportedContent">
				<ul class="navbar-nav me-auto mb-2 mb-lg-0">
					<li class="nav-item">
						<router-link class="nav-link" to="/admin">Statistics</router-link>
					</li>
					<li class="nav-item">
						<router-link class="nav-link" to="/admin/docs">Reference Documents</router-link>
					</li>
					<li class="nav-item">
						<router-link class="nav-link" to="/admin/employees">Employees</router-link>
					</li>
					<li class="nav-item">
						<router-link class="nav-link" to="/admin/users">Administrators</router-link>
					</li>
				</ul>
			</div>
		</div>
	</nav>
</template>
<script>
	import {app_version,app_version_date} from '@/ver.js'
	export default{
		Name: 'MainNavigation',
		data(){return{
			version: app_version,
			version_date: app_version_date,
		}},
		computed: {
			is_admin(){
				let v = this
				let info = v.get_token_info()
				return info.is_admin
			},
		},
		methods:{
			logout(){
				this.$emit('logout');
			},
		},
	}
</script> 
