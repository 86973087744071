<template>
	<div class="container-fluid mh-65">
		<h1>API Debugging</h1>
		<div class="row">
			<div class="col-md-4">
				<div class="d-flex flex-column">
					<button type="button" class="btn btn-outline-dark mt-1" @click="test_email"><i class="bi bi-envelope"></i> Send a Test Email</button>
					<button type="button" class="btn btn-outline-dark mt-1" @click="test_401">401 Response Code Test: Access Denied Failure</button>
					<button type="button" class="btn btn-outline-dark mt-1" @click="test_433">433 Response Code Test: OTP Verification Failure</button>
					<button type="button" class="btn btn-outline-dark mt-1" @click="test_500">500 Response Code Test: Server Side Failure</button>
				</div>
			</div>
			<div class="col-md-8 d-flex">
				<textarea class="form-control" v-model="output" rows="20"></textarea>
			</div>
		</div>
	</div>
</template> 
<script>
	export default{
		name: 'ApiDebuggingView',
		data(){return{
			output: null,
		}},
		methods: {
			test_email(){
				const v = this
				v.axios.get('api/debug/test/email',response=>{
					v.output = JSON.stringify(response, null, 2)
				}).catch(error=>{
					v.output = JSON.stringify(error, null, 2)
				})
			},
			test_401(){
				const v = this
				v.axios.get('api/debug/test/401',response=>{
					v.output = JSON.stringify(response, null, 2)
				}).catch(error=>{
					v.output = JSON.stringify(error, null, 2)
				})
			},
			test_433(){
				const v = this
				v.axios.get('api/debug/test/433',response=>{
					v.output = JSON.stringify(response, null, 2)
				}).catch(error=>{
					v.output = JSON.stringify(error, null, 2)
				})
			},
			test_500(){
				const v = this
				v.axios.get('api/debug/test/500',response=>{
					v.output = JSON.stringify(response, null, 2)
				}).catch(error=>{
					v.output = JSON.stringify(error, null, 2)
				})
			},
		},
	}
</script>
