<template>
	<div class="container mh-65" id="top">
		<h2>Authorization for Direct Deposit</h2>
		<p>Payment of your wages is made directly to your bank account.  In order to process the payments; we will need a personal blank cheque marked “void” or an encoded bank deposit slip.</p>
		<div class="alert alert-warning" v-show="employee.direct_deposit_authorization!=1"><strong>You must complete the acceptance section at the bottom of this page.</strong></div>
		<div class="alert alert-success" v-show="employee.direct_deposit_authorization==1"><i class="bi bi-check2-circle"></i> You have completed this authorization form.</div>

		<p>I hereby authorize the Northumberland Hills Hospital to deposit my wages to my account as noted on the attached cheque/deposit form.  This authorization will be in force for the duration of my employment with the Hospital.</p>
		
		<ul>
			<li>** Please provide a personal blank cheque or personal account information directly to Human Resources</li>
			<li>** Please complete the TD1 forms for income tax deductions.</li>
			<li>** Please read the Orientation booklet, safety information and the confidentiality policy.</li>
		</ul>
		
		<p>Please return all forms to: <strong>Human Resources</strong></p>

		<p><strong>Please Click below to E-Sign this Authorization.</strong></p>
		<p><strong>YES</strong> - by selecting this box I signify that I have read, understand, and I authorize Northumberland Hills Hospital to deposit my wages directly into the account in line with my provided details.</p>
		<div class="row mb-5">
			<div class="col-sm-12 col-md-8">
				<div class="form-check pe-5">
					<input type="checkbox" class="form-check-input" :true-value="1" :false-value="0" v-model="employee.direct_deposit_authorization" id="agreement1">
					<label class="form-check-label ps-5" for="agreement1">I, <span class="ms-2 me-2 ps-5 pe-5 d-inline-block border-bottom signature-font fs-3"><strong>{{signature_display}}</strong></span> attest that I have reviewed and agree with all of the terms above.</label>
				</div>
			</div>
			<div class="col-sm-12 col-md-4">
				<label class="form-label" for="agreement2">Signed on</label>
				<input type="datetime-local" class="form-control" id="agreement2" v-model="employee.direct_deposit_authorization_date" readonly>
			</div>
		</div>
		<div class="alert alert-success" v-show="employee.direct_deposit_authorization==1"><i class="bi bi-check2-circle"></i> You have completed this agreement.</div>
		<hr />
		<div class="d-flex justify-content-center justify-content-md-end mb-5">
			<router-link type="button" class="btn btn-light mt-1 me-1" to="/">Cancel, Go Back</router-link>
			<button type="button" class="btn btn-light mt-1 me-1" @click="save_employee(true)">Save &amp; Stay on the Page</button>
			<button type="button" class="btn btn-light mt-1" @click="save_employee(false)">Save &amp; Go Back</button>
		</div>
	</div>
</template>
<script>
	
	const moment = require('moment')

	export default{
		name: 'DirectDepositView',
		data(){return{
			userinfo: {},
			stats: {},
			employee: {
				direct_deposit_authorization: null,
				direct_deposit_authorization_date: null,
			},
			employee_loaded: false,
			signature_display: null, // Temp used to display the name IF the user has signed the agreement
			save_count: 0,
		}},
		watch: {
			'employee.direct_deposit_authorization': function(newvalue){
				let v = this
				if( newvalue == 1 )
				{
					v.signature_display = v.userinfo.fullname
					v.employee.direct_deposit_authorization_date = moment().format(v.formats.datetime.html)
				}
				else
				{
					v.signature_display = null
					v.employee.direct_deposit_authorization_date = null
				}
			},
		},
		methods: {
			load_employee(employee_id){
				// Loads the Employee Record
				let v = this
				if( employee_id == 0 )
				{
					// Only the couple fields are needed for the update
					v.employee = {
						employee_id: 0,
						direct_deposit_authorization: null,
						direct_deposit_authorization_date: null,
					}
					v.employee_loaded = true
				}
				else
				{
					v.axios.get('/api/employee/'+String(employee_id)).then(response=>{
						v.employee = response.data.employee
						v.stats = response.data.stats
						v.employee_loaded = true
					})
				}
			},
			save_employee(stay_on_page=true){
				const v = this
				v.axios.post('/api/employee',v.employee).then(response=>{
					v.$toast('Information Saved','success');
					v.employee = response.data.employee
					v.save_count++
					if( !stay_on_page )
						v.$router.push('/')
				})
				
			},
		},
		mounted(){
			let v = this
			v.userinfo = v.get_token_info()
			v.load_employee(v.userinfo.id)
		},
	}
</script> 
