<template>
	<div class="container mt-3 mh-65">
		<h2>Police Check</h2>
		<p>As a condition of your employment, you are required to provide an acceptable original <u>Complete Police Record Check including a Pardoned Sexual Offender Database Check</u> dated within 60 days of your start date.  Please note, supplying inaccurate information and/or a complete police record check containing a criminal record may be grounds for discipline up to an including dismissal. </p>

		<label class="form-label"><strong>Select the appropriate status of your Police Record Check</strong></label>
		<div class="form-check">
			<input class="form-check-input" type="radio" v-model="employee.police_record_check_status" value="supplied" id="police-check-radio1">
			<label class="form-check-label" for="police-check-radio1">
				Supplied - Submit Police Check to NHH
			</label>
		</div>
		<div class="form-check">
			<input class="form-check-input" type="radio" v-model="employee.police_record_check_status" value="applied" id="police-check-radio2">
			<label class="form-check-label" for="police-check-radio2">
				Applied - Have applied , In Progress
			</label>
		</div>
		<hr />
		<p class="text-center text-muted" v-if="certs.length<=0">You have not uploaded any police check documents.</p>
		<div v-if="certs.length>0 && employee.police_record_check_status">
			<table class="table">
				<thead>
					<tr>
						<th>Filename</th>
						<th colspan="2">Type</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="c in certs" :key="c.employee_document_id">
						<td>{{c.filename}}</td>
						<td>{{c.document_type_id}}</td>
						<td>
							<button type="button" class="btn btn-dark" @click="delete_document(c.employee_document_id)"><i class="bi bi-trash"></i></button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<hr />
		<section v-if="employee.police_record_check_status=='supplied' || employee.police_record_check_status=='applied'">
			<h3 class="fs-5">Instructions:</h3>
			<ul>
				<li v-if="employee.police_record_check_status=='supplied'">Upload Your <strong>Police Record Check</strong></li>
				<li v-if="employee.police_record_check_status=='applied'">Upload Your <strong>Police Record Application Form</strong></li>
				<li>If there are multiple pages, submit all pages one after the other.</li>
			</ul>
			<div class="mb-5">
				<label class="form-label" for="cert-upload">Upload one or many files at a time to your Certificate List</label>
				<CIFileUploader action='api/employee/upload' input-name="police_file" :extra-params="{type:'police-check',employee_id:employee.employee_id}" @after-upload="load_documents" />
			</div>
			<hr />
		</section>
		<div class="d-flex justify-content-center mt-2 mb-5">
			<button type="button" class="btn btn-light" @click="$router.go(-1)">Back to Dashboard</button>
		</div>
	</div>
</template>
<script>
	import CIFileUploader from '@/components/CIFileUploader.vue'
	export default { 
		name: 'CertificatesView',
		components: {CIFileUploader},
		data(){return{
			certs: [],
			userinfo: {},
			employee: {},
		}},
		watch: {
			'employee.police_record_check_status': function(){
				this.save_employee(true)
			},
		},
		methods: {
			load_employee(employee_id){
				// Loads the Employee Record
				let v = this
				if( employee_id == 0 )
				{
					// Only the couple fields are needed for the update
					v.employee = {
						employee_id: 0,
						police_record_check_status: null,
					}
					v.employee_loaded = true
				}
				else
					v.axios.get('/api/employee/'+String(employee_id)).then(response=>{
						v.employee = response.data.employee
						v.stats = response.data.stats
						v.employee_loaded = true
					})
			},
			save_employee(stay_on_page=true){
				const v = this
				v.axios.post('/api/employee',v.employee).then(response=>{
					v.$toast('Information Saved','success');
					v.employee = response.data.employee
					v.stats = response.data.stats
					if( !stay_on_page )
						v.$router.push('/')
				})
			},
			/** 
			 * Load certificate files from the server as a list
			 */
			load_documents(){
				let v = this
				v.axios.get('api/policechecks').then(response=>{
					v.certs = response.data
				})
			},
			delete_document(employee_document_id){
				let v = this

				v.$confirm({
					text:'Are you sure you want to remove this qualification document?',
					success(){
						v.axios.delete('api/employee/document/'+String(employee_document_id)).then(response=>{
							v.$toast('Document Removed','success')
							v.load_documents()
						})
					}
				})
			},
		},
		mounted(){
			let v = this

			v.userinfo = v.get_token_info()

			let id = v.$route.params.id

			if( id )
			{
				v.load_employee(id)
				return
			}

			if( v.userinfo.id )
				v.load_employee(v.userinfo.id)
			else
				v.load_employee(0)
			
			v.load_documents()
		}
	}
</script>
