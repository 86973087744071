<template>
	<div class="container mx-auto mh-65">
		<form @submit.prevent="send">
			<div class="border rounded p-5 mt-5 mx-auto bg-td-section-gray mb-5" style="max-width: 600px;">
				<div v-if="!submitted && !verified && !complete">
					<h1>Reset your Password or Access Link</h1>
					<ul>
						<li><strong>DO NOT close this window until the process is complete!</strong></li>
						<li>Submit your email.</li>
						<li>If you are an admin you will receive a code to reset your password in your email.</li>
						<li>If you are a candidate or employee, you will recieve an entirely new access code for accessing your account.</li>
						<li>If you were provided with a reset code in the email, enter it exactly as shown (eg. 4271) when prompted</li>
						<li>You will then be prompted to reset your password.</li>
						<li>If you are an employee the process will end with the request and you will simply receive a new link in your email inbox to access the site.</li>
					</ul>
				</div>
				<h1 v-if="submitted && !verified && !complete">Check Your Email</h1>
				<h1 v-if="submitted && verified && !complete">Set a new Password</h1>
				<h1 v-if="complete">Your password has been Reset!</h1>
				<hr />
				<div v-if="!submitted && !verified && !complete">
					<label for="email" class="form-label">Provide your email address:</label>
					<input type="text" v-model="email" class="form-control" />
					<div class="mt-3 text-center">
						<button v-if="!sending" type="submit" class="btn btn-primary" @click="send_reset">Request Password Reset</button>
						<div v-if="sending">
							<div class="badge text-bg-info">Sending email, please wait...</div>
						</div>
						<div v-if="error_msg">
							<div class="badge text-bg-danger">{{error_msg}}</div>
						</div>
					</div>
				</div>
				<div v-if="submitted && !verified && !complete">
					<div class="alert alert-success">
						<p>Password Reset Email Sent!</p>
						<ul>
							<li><strong>Do Not Refresh</strong> this page or you will have to start over.</li>
						</ul>
					</div>

					<label for="code" class="form-label">Please enter the code we have sent you in your email:</label>
					<div class="row">
						<div class="col-md-4"></div>
						<div class="col-md-4 mt-3">
							<input type="text" v-model="code" class="form-control text-center" />
						</div>
					</div>

					<div class="mt-3 text-center">
						<button type="submit" class="btn btn-primary" @click="check_code">Submit Verification Code</button>
					</div>
				</div>
				<div v-if="submitted && verified && !complete">
					<div class="alert alert-success">
						<p>Your code has been verified, please reset your password!</p>
						<p>Remember that you will have to also confirm it in the 2nd box in order to complete the process.</p>
					</div>
					<label for="new_password" class="form-label">Enter Your New Password</label>
					<input type="password" v-model="new_password" class="form-control" id="new_password" />
					<label for="confirm_password" class="form-label">Confirm your New Password</label>
					<input type="password" v-model="confirm_password" class="form-control" id="confirm_password" />

					<div class="mt-3 text-center">
						<button type="submit" class="btn btn-primary" @click="send_password">Set new Password</button>
					</div>
				</div>
				<div v-if="complete">
					<div class="alert alert-success">
						<p>Your password has been successfully reset, please return to the home page to login.</p>
					</div>
					<div class="mt-3 text-center">
						<button type="submit" class="btn btn-primary" @click="window.location='/'">Return to Login Page</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>
<script>
	export default{
		Name: 'PasswordResetView',
		data(){return{
			email: null,
			code: null,
			submitted: false,
			verified: false,
			complete: false,
			token: null,
			sending: false,
			error_msg: null,
		}},
		methods: {
			send_reset(){
				const v = this
				let payload = {
					email: v.email,
				}
				v.sending = true
				v.axios.post('/api/reset',payload).then(response=>{
					console.log(response)
					v.token = response.data.token
					v.submitted = true
				}).catch(err=>{
					console.error(err)
					if( err.response.data.messages.error )
						v.error_msg = err.response.data.messages.error
					else
						v.error_msg = 'An error has occurred'
					v.submitted = false
				}).finally(()=>{
					v.sending = false
				})
			},
			check_code(){
				const v = this
				let payload = {
					code: v.code,
				}
				v.sending = true
				v.axios.post('/api/reset/check',payload).then(response=>{
					console.log(response)
					v.verified = true
				}).catch(err=>{
					console.error(err)
					if( err.response.data.messages.error )
						v.error_msg = err.response.data.messages.error
					else
						v.error_msg = 'An error has occurred'
					v.verified = false
				}).finally(()=>{
					v.sending = false
				})
			},
			send_password(){
				const v = this

				if( v.new_password != v.confirm_password )
					v.error_msg = 'Passwords do not match.';

				let payload = {
					token: v.token,
					password: v.new_password,
				}
				alert('Token is: '+String(v.token))
				v.sending = true
				v.complete = false
				v.axios.post('/api/reset/password',payload).then(response=>{
					console.log(response)
					v.complete = true
				}).catch(err=>{
					console.error(err)
					if( err.response.data.messages.error )
						v.error_msg = err.response.data.messages.error
					else
						v.error_msg = 'An error has occurred'
				}).finally(()=>{
					v.sending = false
				})
			},
		},
		mounted(){
			const v = this
			//v.send_reset()
		},
	}
</script> 
