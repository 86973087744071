<style scoped>
	.success-indicator{
		position: absolute;
		top: 3px;
		right: 3px;
		text-align: center;
		font-size: 24pt;
	}
	.incomplete-indicator{
		position: absolute;
		top: 3px;
		right: 3px;
	}
	.sidenav{font-size: 1.1em;}
	.module-link{color: #8c8c8c !important;}
	.module-link:hover{cursor:pointer;color:#8b0b04 !important;}
	a.refdoc{
		font-weight: normal;
		/* color: #000;*/
	}
	.orientation-box{
		min-height: 185px;
	}
</style>
<template>
	<div class="container-xxl mb-5">
		<div class="row mt-5">
			<div class="col-md-3 bg-light ps-4 pe-4">
				<h3 class="sidenav mt-4">Reference Documents</h3>
				<hr />
				<div class="d-flex justify-content-between mt-3 mb-3" v-for="document in documents" :key="document.reference_document_id">
					<a :href="'/reference_documents/'+document.filename" class="refdoc">{{document.title}}</a>
					<i class="bi bi-caret-right-fill"></i>
				</div>
			</div>
			<div class="col-md-9 ps-sm-3 ps-md-5 mt-5 mt-md-3">
				<h2>Orientation Dashboard</h2>
				<hr />
				<div v-if="stats.locked">
					<div class="orientation-box border p-5 position-relative mb-5">
						<div class="row">
							<div class="col">
								<h3>You have completed the Orientation Process!<br />What's Next?</h3>
								<ul>
									<li>You have completed all steps of the process!</li>
									<li>Human Resources has been notified of your completed Profile and will review shortly and contact you.</li>
									<li>Contact the Human Resource Department at Northumberland Hills Hospital if you have any further questions.</li>
									<li>Review the available Reference Documents and additional information below!</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div v-if="stats.total_progress>=100 && !stats.locked">
					<div class="orientation-box border p-5 position-relative mb-5">
						<div class="row">
							<div class="col-md-8">
								<h3>Congratulations, you have Finished All Steps!</h3>
								<ul>
									<li>You have completed all steps of the process!</li>
									<li>Take a few minutes to review your responses.</li>
									<li>By clicking the button to the right, you will secure your information with us and Human Resources staff at Northumberland Hills Hospital will be Notified that your information is ready for review.</li>
									<li><strong>Please Note!</strong> once locked, you won't be able to make any changes to your information afterwards!</li>
								</ul>
							</div>
							<div class="col-md-4">
								<button class="btn btn-success text-white" @click="lock_employee"><i class="bi bi-check-circle"></i> Yes I am finished, Lock my Profile and Notify Human Resources.</button>
							</div>
						</div>
					</div>
				</div>
				<section id="training-modules" v-if="!stats.locked">
					<h3>Your Training Modules</h3>
					<div class="row">
						<div class="col-md-4 mt-3" v-for="icon,idx in icons" :key="idx">
							<div class="p-2 text-center">
								<router-link :to="icon.to" class="module-link">
									<div class="orientation-box border p-5 d-flex align-items-center justify-content-center position-relative">
										<div>
											<div class="success-indicator" v-if="icon.completed==100">
												<i class="bi bi-check-circle-fill text-success"></i>
											</div>
											<div class="incomplete-indicator" v-if="icon.completed<100">
												<div class="badge bg-warning text-black">{{icon.completed}}% Completed</div>
											</div>
											<div>
												<i class="bi fs-3" :class="icon_class(icon)"></i>
											</div>
											<div>{{icon.caption}}</div>
										</div>
									</div>
								</router-link>
							</div>
						</div>
					</div>
				</section>
				<section id="static-content">
					<h2>Additional Orientation Information</h2>
					<hr />
					<div class="row">
						<div class="col-md-3">
							<h4>To Read</h4>
						</div>
						<div class="col">
							<p class="mt-0 pt-0">Welcome to Northumberland Hills Hospital. Your general orientation information will be included within your offer of employment. Please make sure to review the dates and ensure you are available to attend. Your Manager will be in touch to schedule appropriate departmental orientation. If you do not hear from them before your first day on site, please contact us directly.</p>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3"><h4>To Bring</h4></div>
						<div class="col">
							<p class="mt-0 pt-0">On your first date of employment, and on site, please make sure to bring the following:</p>
							<ul>
								<li>Banking Information (void cheque, print out of Account information)
									<ul>
										<li>Please be advised that we can not take handwritten details relating to your banking information.</li>
									</ul>
								</li>
								<li>Photographic ID, proof of eligibility to work in Canada.
									<ul>
										<li>(Canadian Passport, Birth Certificate or Visa/Work Permit)</li>
										<li>Please be advised that HR is required to verify the submitted forms of ID with the physical, original copies on your first day.</li>
									</ul>
								</li>
								<li>Social Insurance Number Document</li>
							</ul>
						</div>
					</div>
					<div class="row">
						<div class="col">
							<h4>Other Important Information</h4>
							<p>As part of your employment, you are required to provide documentation related to communicable disease vaccination and immunity. Please complete the Communicable Disease Surveillance form located above in the reference documents section and gather any relevant information as per the form. You may complete this form yourself or you can ask your physician’s office to provide the needed information. All documentation should then be submitted to Occupational Health via email at ohs@nhh.ca. If it is not submitted ahead of your first day, printed copies must be brought with you on the first day of General Orientation. You will be contacted by Occupational Health regarding any outstanding items once your information has been reviewed.</p>
							<p>If you are starting outside of a General Orientation day, please make sure to visit Human Resources on your first day to drop off your direct deposit information, you will also be provided your IT credentials, and we will take your photo for your ID badge.</p>
							<p>It is your responsibility to ensure that all of the relevant documentation is provided. HR will endeavour to assist you along the way. For any questions, comments or concerns you can contact the HR team directly via email at <a href="mailto:askHR@nhh.ca">askHR@nhh.ca</a> or by phone <strong>905-372-6811 ext. 4040.</strong></p>
						</div>
					</div>
				</section>
			</div>
		</div>
	</div><!--container-->
</template> 
<script>
	export default {
		Name: 'EmployeeDashboardView',
		data(){return{
			icons: [],
			documents: [],
			stats: {},
			userinfo: {},
		}},
		computed: {
			stats_loaded(){
				let v = this
				return Object.keys(v.stats) > 0
			},
		},
		methods:{
			api_base(){
				return process.env.VUE_APP_API_BASE_URL;
			},
			lock_employee(){
				let v = this
				v.$prompt({
					text: 'Are you sure you wish to LOCK your profile and Notify Human Resources that you have completed the intake process? This cannot be un-done, You must type "YES" to proceed!',
					success: result=>{
						if( String(result).match(/[yY][eE][sS]/) )
						{
							let userinfo = v.get_token_info()
							v.axios.post('api/employee/lock/'+String(userinfo.id)).then(response=>{
								v.load_stats()
							})
						}
						else
							v.toast('Cancelled','info');	
					},
					cancel(){
						v.toast('Cancelled','info');
					},
				})
			},
			load_documents(){
				let v = this
				v.axios.get('api/documents').then(response=>{
					v.documents = response.data
				})
			},
			load_stats(){
				let v = this
				v.axios.get('api/employee/stats').then(response=>{
					v.stats = response.data
					v.stats.locked = v.stats.locked ? parseInt(v.stats.locked) : 0;
					v.icons = [
						{caption:'Welcome', icon: 'bi-hospital', completed: v.stats.employee_percent, to: '/employee#top'},
						{caption:'Police Check', icon: 'bi-person-lock', completed: v.stats.police_percent, to: '/policecheck#top'},
						{caption:'French Aptitude', icon: 'bi-chat', completed: v.stats.french_percent, to: '/french#top'},
						{caption:'Certificates', icon: 'bi-award', completed: v.stats.qualifications_percent, to: '/certificates#top'},
						{caption:'Health and Safety', icon: 'bi-shield-shaded', completed: v.stats.health_safety_quiz_percent, to: '/healthandsafety'},
						{caption:'New Hire Attestation', icon: 'bi-ui-checks', completed: v.stats.attestation_percent, to: '/attestation'},
						{caption:'Confidentiality', icon: 'bi-file-lock', completed: v.stats.confidentiality_percent, to: '/confidentiality'},
						{caption:'Values and Behaviours', icon: 'bi-card-checklist', completed: v.stats.values_percent, to: '/values'},
						{caption:'TD1 Forms', icon: 'bi-file-earmark-text', completed: v.stats.td1_forms_percent, to: '/td1forms'},
						{caption:'Parking', icon: 'bi-p-circle', completed: v.stats.parking_percent, to: '/parking'},
						{caption:'Foundation', icon: 'bi-building-add', completed: v.stats.foundation_lottery_percent, to: '/foundationlottery'},
						{caption:'Direct Deposit', icon: 'bi-cash-coin', completed: v.stats.direct_deposit_percent, to: '/directdeposit'},
						{caption:'HOOPP', icon: 'bi-cash-coin', completed: v.stats.hoop_authorization_percent, to: '/hoopauth'},
					]
				})
			},
			icon_class(ico)
			{
				if( typeof ico != 'object' && ico.icon != undefined )
				{
					return {
						'bi-file-earmark-check': true,
					}
				}
				else
				{
					let classes = {}
					classes[ico.icon] = true
					return classes
				}
			}
		},
		mounted(){
			let v = this

			let userinfo = v.get_token_info()
			if( userinfo.is_admin )
				v.$router.push('/admin')
			
			v.load_documents()
			v.load_stats()
		},
	}
</script>
