<template>
	<div class="container mh-65">
		<h2>User Admin</h2>

		<div class="mt-4 mb-2">
			<h5>Filters</h5>
			<form @submit.prevent="null">
				<div class="row">
					<div class="col-sm-12 col-md-4">
						<div class="input-group">
							<input type="text" class="form-control" placeholder="Search by keyword.." role="search" v-model="search_keyword">
							<button type="button" class="btn btn-outline-secondary" aria-label="Search reset button, clears the search criteria." @click="search_keyword=''">
								<i class="bi bi-x"></i>
							</button>
						</div>
					</div>
					<div class="col-sm-12 col-md-8 text-end">
						<router-link class="btn btn-primary" to="/admin/user"><i class="bi bi-plus"></i> Add New User/Administrator</router-link>
					</div>
				</div>
			</form>
		</div>

		<table class="table">
			<thead>
				<tr>
					<th>Full Name</th>
					<th>Email</th>
					<th>Status</th>
					<th colspan="2">Created</th>
				</tr>
			</thead>
			<tbody v-if="users_loaded">
				<tr v-for="e,idx in users" :key="idx">
					<td>{{e.full_name}}</td>
					<td>{{e.email}}</td>
					<td>
						<div class="badge bg-success" v-show="e.suspend=='0'">Active</div>
						<div class="badge bg-dark" v-show="e.suspend=='1'">Suspended</div>
					</td>
					<td>{{e.created}}</td>
					<td>
						<router-link class="btn btn-sm btn-secondary" :to="'/admin/user/'+String(e.user_id)">Edit</router-link>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
	export default{
		name: 'UserAdminView',
		data(){return{
			users_loaded: false,
			users: [],
			search_keyword: '',
			filter_timeout: null,
		}},
		watch: {
			search_keyword(){
				let v = this
				if( v.filter_timeout) 
					clearTimeout(v.filter_timeout)
				v.filter_timeout = setTimeout(function(){
					v.load_users()
				},300);
			},
		},
		methods: {
			load_users(){
				let v = this
				v.axios.get('/api/admin/users?filter='+String(v.search_keyword)).then(response=>{
					v.users = response.data
					v.users_loaded = true
				})
			},
		},
		mounted(){
			let v = this
			
			let userinfo = v.get_token_info()
			if( !userinfo.is_admin )
				v.$router.push('/')

			v.load_users()
		}
	}
</script> 
 
