<template>
	<div class="container mh-65" id="top">
		<h2>Values and Corresponding Behaviours</h2>
		<p>A set of values and corresponding behaviours has been developed by the employees of Northumberland Hills Hospital establishing specific behaviours that all employees and volunteers must demonstrate while on duty. All applicants, employees and volunteers alike, are required to be aware of these values and corresponding behaviours and, by signing below, agrees to comply with them throughout their service/employment at Northumberland Hills Hospital.</p>		
		<div class="alert alert-warning" v-show="employee.values_agreement!=1"><strong>You must complete the acceptance section at the bottom of this page.</strong></div>
		<div class="alert alert-success" v-show="employee.values_agreement==1"><i class="bi bi-check2-circle"></i> You have completed this agreement.</div>
		<table class="table">
			<thead>
				<tr>
					<th>Value</th>
					<th>Description</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td><strong>TEAMWORK:</strong></td>
					<td>working together in collaborative partnerships in an environment of trust and respect to achieve the best possible care.</td>
				</tr>
				<tr>
					<td><strong>RESPECT:</strong></td>
					<td>flows from appreciating each person for the qualities and experiences that they bring.</td>
				</tr>
				<tr>
					<td><strong>COMPASSION:</strong></td>
					<td>means having empathy for our patients, families and each other</td>
				</tr>
				<tr>
					<td><strong>QUALITY:</strong></td>
					<td>is where high standards and innovation meet</td>
				</tr>
				<tr>
					<td><strong>INTEGRITY:</strong></td>
					<td>lies in being true to ourselves, our team and our patients</td>
				</tr>
			</tbody>
		</table>

		<p><strong>Please Click below to E-Sign this Agreement.</strong></p>
		<p><strong>YES</strong> - By selecting this box I signify that I have read, understand, and I agree to comply with and practice the NHH Values outlined above.</p>
		<div class="row mb-5">
			<div class="col-sm-12 col-md-8">
				<div class="form-check pe-5">
					<input type="checkbox" class="form-check-input" :true-value="1" :false-value="0" v-model="employee.values_agreement" id="agreement1">
					<label class="form-check-label ps-5" for="agreement1">I, <span class="ms-2 me-2 ps-5 pe-5 d-inline-block border-bottom signature-font fs-3"><strong>{{signature_display}}</strong></span> attest that I have reviewed and completed all the modules as outlined above.</label>
				</div>
			</div>
			<div class="col-sm-12 col-md-4">
				<label class="form-label" for="agreement2">Signed on</label>
				<input type="datetime-local" class="form-control" id="agreement2" v-model="employee.values_agreement_date" readonly>
			</div>
		</div>
		<div class="alert alert-success" v-show="employee.values_agreement==1"><i class="bi bi-check2-circle"></i> You have completed this agreement.</div>
		<hr />
		<div class="d-flex justify-content-center justify-content-md-end mb-5">
			<router-link type="button" class="btn btn-light mt-1 me-1" to="/">Cancel, Go Back</router-link>
			<button type="button" class="btn btn-light mt-1 me-1" @click="save_employee(true)">Save &amp; Stay on the Page</button>
			<button type="button" class="btn btn-light mt-1" @click="save_employee(false)">Save &amp; Go Back</button>
		</div>
	</div>
</template>
<script>
	
	const moment = require('moment')

	export default{
		name: 'ValuesView',
		data(){return{
			userinfo: {},
			stats: {},
			employee: {
				values_agreement: null,
				values_agreement_date: null,
			},
			employee_loaded: false,
			signature_display: null, // Temp used to display the name IF the user has signed the agreement
			save_count: 0,
		}},
		watch: {
			'employee.values_agreement': function(newvalue){
				let v = this
				if( newvalue == 1 )
				{
					v.signature_display = v.userinfo.fullname
					v.employee.values_agreement_date = moment().format(v.formats.datetime.html)
				}
				else
				{
					v.signature_display = null
					v.employee.values_agreement_date = null
				}
			},
		},
		methods: {
			load_employee(employee_id){
				// Loads the Employee Record
				let v = this
				if( employee_id == 0 )
				{
					// Only the couple fields are needed for the update
					v.employee = {
						employee_id: 0,
						values_agreement: null,
						values_agreement_date: null,
					}
					v.employee_loaded = true
				}
				else
				{
					v.axios.get('/api/employee/'+String(employee_id)).then(response=>{
						v.employee = response.data.employee
						v.stats = response.data.stats
						v.employee_loaded = true
					})
				}
			},
			save_employee(stay_on_page=true){
				const v = this
				v.axios.post('/api/employee',v.employee).then(response=>{
					v.$toast('Information Saved','success');
					v.employee = response.data.employee
					v.save_count++
					if( !stay_on_page )
						v.$router.push('/')
				})
				
			},
		},
		mounted(){
			let v = this
			v.userinfo = v.get_token_info()
			v.load_employee(v.userinfo.id)
		},
	}
</script> 
