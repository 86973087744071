<template>
	<button type="button" class="btn btn-outline-primary" @click="click_evt" v-if="type=='button'">
		<i class="bi bi-clipboard2" v-show="!clipped"></i>
		<i class="bi bi-clipboard2-check" v-show="clipped"></i>
		{{text}}
	</button>
	<a href="#" @click="click_evt" v-if="type!='button'">
		<i class="bi bi-clipboard2" v-show="!clipped"></i>
		<i class="bi bi-clipboard2-check" v-show="clipped"></i>
		{{text}}
	</a>
</template>
<script>
export default{
	name: 'ClipLink',
	props: ['clip','text','type'],
	data(){return{
		clipped: false,
	}},
	computed:{
		inner_text(){
			return this.innerText
		}
	},
	methods:{
		click_evt(event){
			let v = this
			navigator.clipboard.writeText(v.clip);
			v.clipped = true
			setTimeout(function(){v.clipped=false},1500);
		},
	},
}
</script> 
