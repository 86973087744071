<template>
	<div class="container mt-3 mh-65">
		<h2>TD1 Forms</h2>
		<p>Provincial and Federal TD1 Forms are available from the Canada Revenue Agency.</p>
		<h3>Instructions</h3>
		<ol>
			<li>Download both of the following TD1 Forms (available from the CRA Website) at the following two links;
				<ul>
					<li><a href="/static_documents/td1-fill-24e.pdf" target="_blank">Download the <strong>Federal TD1 Form</strong></a></li>
					<li><a href="/static_documents/td1on-fill-24e.pdf" target="_blank">Download the <strong>Ontario Provincial TD1 Form</strong></a></li>
				</ul>
			</li>
			<li>Fill out both of these TD1 Forms digitally using <a href="https://get.adobe.com/reader/" target="_blank" aria-label="Download Adobe PDF Reader">Adobe PDF Reader</a> (or other compatible PDF Reader).</li>
			<li>Save your changes to the documents and then upload the completed forms/files below.</li>
			<li><strong>Remember that you must fill out and submit both forms.</strong></li>
		</ol>
		<hr />
		<p class="text-center text-muted" v-if="certs.length<=0">You have not uploaded any certificates.</p>
		<div v-if="certs.length>0">
			<table class="table">
				<thead>
					<tr>
						<th>Filename</th>
						<th colspan="2">Type</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="c in certs" :key="c.employee_document_id">
						<td>{{c.filename}}</td>
						<td>{{c.document_type_id}}</td>
						<td>
							<button type="button" class="btn btn-dark" @click="delete_document(c.employee_document_id)"><i class="bi bi-trash"></i></button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<hr />
		<h3 class="fs-5">Instructions:</h3>
		<div class="mb-5">
			<label class="form-label" for="cert-upload">Upload both of your TD1 Forms once you've filled them out.</label>
			<CIFileUploader action='api/employee/upload' input-name="td1_form" id="cert-upload" multiple="true" :extra-params="{type:'td1'}" @after-upload="load_documents" />
		</div>
		<div class="d-flex justify-content-center mb-5">
			<button type="button" class="btn btn-light" @click="$router.go(-1)">Back to Dashboard</button>
		</div>
	</div>
</template>
<script>
	import CIFileUploader from '@/components/CIFileUploader.vue'
	export default { 
		name: 'CertificatesView',
		components: {CIFileUploader},
		data(){return{
			certs: [],
			userinfo: {},
		}},
		methods: {
			/** 
			 * Load certificate files from the server as a list
			 */
			load_documents(){
				let v = this
				v.axios.get('/api/td1').then(response=>{
					v.certs = response.data
				})
			},
			delete_document(employee_document_id){
				let v = this

				v.$confirm({
					text:'Are you sure you want to remove this TD1 Form Submission?',
					success(){
						v.axios.delete('/api/employee/document/'+String(employee_document_id)).then(response=>{
							v.$toast('Document Removed','success')
							v.load_documents()
						})
					}
				})
			},
		},
		mounted(){
			let v = this
			v.userinfo = v.get_token_info()
			v.load_documents()
		}
	}
</script>
