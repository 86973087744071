<template>
	<div class="container mh-65">
		<div class="row">
			<div class="col">
				<h1>Administrator Account</h1>
				<p>Administrator accounts have universal access at this time. Anyone who has the right to login has full access to the system.</p>
			</div>
			<div class="col">
				<div class="d-flex flex-row-reverse">
					<div>
						<button type="button" class="btn btn-light ms-1" @click="delete_user"><i class="bi bi-trash"></i> Delete Account</button>
						<button type="button" class="btn btn-light ms-1" @click="cancel_click"><i class="bi bi-box-arrow-left"></i> Back / Close</button>
					</div>
				</div>
			</div>
		</div>
		<hr />
		<div class="row">
			<div class="col border-end">
				<section v-if="!edit_user">
					<fieldset>
						<legend>User Details</legend>
						<div class="row">
							<div class="col">
								<label class="col-form-label" for="user-username">User Name</label>
							</div>
							<div class="col">
								<input type="text" class="form-control-plaintext" v-model="user.username" id="user-username" readonly>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<label class="col-form-label" for="user-full_name">Full Name</label>
							</div>
							<div class="col">
								<input type="text" class="form-control-plaintext" v-model="user.full_name" id="user-full_name" readonly>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<label class="col-form-label" for="user-email">Email</label>
							</div>
							<div class="col">
								<input type="text" class="form-control-plaintext" v-model="user.email" id="user-email" readonly>
							</div>
						</div>
						<div class="row">
							<div class="col"></div>
							<div class="col">
								<button type="button" class="btn btn-light" @click="edit_user=true">Edit User Details</button>
							</div>
						</div>
					</fieldset>
				</section>
				<section v-if="edit_user"> <!--readonly-->
					<h2><span v-if="user.user_id>0">Edit</span><span v-if="user.user_id==0">Enter New</span> User Details</h2>
					<div class="row">
						<div class="col">
							<label class="col-form-label" for="user-username">User Name</label>
						</div>
						<div class="col">
							<input type="text" class="form-control" v-model="user.username" id="user-username">
						</div>
					</div>
					<div class="row">
						<div class="col">
							<label class="col-form-label" for="user-full_name">Full Name</label>
						</div>
						<div class="col">
							<input type="text" class="form-control" v-model="user.full_name" id="user-full_name">
						</div>
					</div>
					<div class="row">
						<div class="col">
							<label class="col-form-label" for="user-email">Email</label>
						</div>
						<div class="col">
							<input type="text" class="form-control" v-model="user.email" id="user-email">
						</div>
					</div>
					<div class="row">
						<div class="col"></div>
						<div class="col">
							<div class="mt-1 d-flex w-100 justify-content-right">
								<button v-if="user.user_id" type="button" class="btn btn-secondary d-block" @click="edit_user=false;">Cancel</button>
								<button type="button" class="btn btn-primary d-block ms-1" @click="save();edit_user=false;">Save Changes</button>
							</div>
						</div>
					</div>
				</section>
			</div>
			<div class="col">
				<fieldset v-if="access_visible">
					<legend>Access</legend>
					<div v-if="!edit_password">
						<div class="row">
							<div class="col">
								<label class="col-form-label" for="account-status">Account Status</label>
							</div>
							<div class="col">
								<div class="mt-1">
									<div class="badge bg-success" v-if="!suspend">Active</div>
									<div class="badge bg-danger" v-if="suspend">Suspended</div>
								</div>
							</div>
							<div class="col">
								<button type="button" class="btn btn-light btn-sm" v-if="suspend" @click="suspend_account(false)">Activate</button>
								<button type="button" class="btn btn-light btn-sm" v-if="!suspend" @click="suspend_account(true)">Suspend</button>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<label class="col-form-label" for="user-password">Password</label>
							</div>
							<div class="col">
								<input type="password" id="user-password" class="form-control-plaintext" value="dummypassword" aria-label="Dummy Password, not actual password">
							</div>
							<div class="col">
								<button type="button" class="btn btn-sm btn-light" @click="edit_password=true">Change Password</button>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<hr />
								<strong>Permissions</strong>
								<div class="form-check">
									<label class="form-check-label" for="super_admin">Administrator is a Super User and can manage other Administrative User Accounts</label>
									<input type="checkbox" class="form-check-input" v-model="user.super_admin" id="super_admin" :true-value="1" :false-value="0" @change="save">
								</div>
							</div>
						</div>
					</div>
					<div v-if="edit_password">
						<div class="row">
							<div class="col">
								<label class="col-form-label" for="user-password">New Password</label>
							</div>
							<div class="col">
								<div class="input-group">
									<input type="password" v-if="!show_password" id="user-password" class="form-control" v-model="new_password">
									<input type="text" v-if="show_password" id="user-password" class="form-control" v-model="new_password">
									<button type="button" class="btn btn-light" v-show="!show_password" @click="show_password=true"><i class="bi bi-eye"></i></button>
									<button type="button" class="btn btn-light" v-show="show_password" @click="show_password=false"><i class="bi bi-eye-slash"></i></button>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<label class="col-form-label" for="user-password2">Confirm Password</label>
							</div>
							<div class="col">
								<input type="password" id="user-password2" class="form-control" v-model="new_password2">
							</div>
						</div>
						<div class="row">
							<div class="col">
							</div>
							<div class="col">
								<div class="mt-1">
									<button type="button" class="btn btn-primary" @click="save_new_password">Save Changes</button>
									<button type="button" class="btn btn-secondary ms-1" @click="edit_password=false">Cancel</button>
								</div>
							</div>
						</div>
					</div>
				</fieldset>
			</div>
		</div>
	</div>
</template>
<script>
	export default{
		name: 'UserAdminEditView',
		data(){return{
			user: {
				user_id: 0,
				full_name: null,
				username: null,
				email: null,
				super_admin: 0,
			},
			suspend: 0,
			new_password: null,
			new_password2: null,
			show_password: false,
			edit_user: false,
			edit_password: false,
			error_msg: 'Example Error Message',
			access_visible: true,
		}},
		methods: {
			cancel_click(){
				const v = this
				v.$router.push('/admin/users');
			},
			load_user(user_id){
				let v = this
				v.axios.get('/api/user/'+String(user_id)).then(response=>{
					v.user = response.data
					v.suspend = parseInt(response.data.suspend)
				})
			},
			save(){
				let v = this
				let payload = {
					user_id: v.user.user_id,
					full_name: v.user.full_name,
					username: v.user.username,
					email: v.user.email,
					super_admin: v.user.super_admin,
				}
				v.axios.post('/api/user',payload).then(response=>{
					v.user = response.data
					v.$toast('Changes Saved','success')
					v.access_visible = true
				}).catch(err=>{
					v.$toast('Changes were not Saved','error')
				})
			},
			delete_user(){
				let v = this
				v.$prompt({
					inputLabel: 'Type "YES" to Delete this Account. This is Permanent.',
					success: input=>{
						if( input.toLowerCase() == 'yes' )
						{
							v.axios.delete('/api/user/'+String(v.user.user_id)).then(response=>{
								v.$toast('User Deleted','question');
								v.$router.push('/admin/users');
							}).catch(err=>{
								v.$toast('Unable to Delete User','error');
							})
						}
					},
				})
			},
			save_new_password(){
				let v = this
				let pwd = String(v.new_password)

				// Validate Password Strength Rules
				let rules = [
					{pass: pwd.length >= 10,	error: 'New Password is too short.'},
					{pass: /[A-Z]/.test(pwd),	error: 'At least one capital letter is required.'},
					{pass: /[a-z]/.test(pwd),	error: 'At least one lowercase letter is required.'},
					{pass: /[0-9]/.test(pwd),	error: 'At least one number is required.'},
					{pass: pwd == v.new_password2,	error: 'New passwords must match, re-type the password a second time to confirm it.'},
				]
				for(let r of rules)
				{
					if( !r.pass )
					{
						v.$toast(r.error,'error')
						return
					}
				}
				v.axios.post('/api/user/passwd',{user_id:v.user.user_id,password:pwd}).then(response=>{
					v.$toast('Password Reset Completed','success')
					v.edit_password = false;
				})
			},
			suspend_account(setting){
				let v = this
				v.suspend = setting ? 1 : 0;
				v.axios.post('/api/user',{user_id:v.user.user_id,suspend:v.suspend}).then(response=>{
					v.suspend = parseInt(response.data.suspend)
					let msg = v.suspend ? 'User Account Suspended':'User Account Un-Suspended'
					v.$toast(msg,'success')
				})
			}
		},
		mounted(){
			let v = this
			
			let userinfo = v.get_token_info()
			if( !userinfo.is_admin )
				v.$router.push('/')

			if( v.$route.params.id )
				v.load_user(v.$route.params.id)
			else
			{
				v.edit_user = true
				v.access_visible = false
			}
		},
	}
</script> 
