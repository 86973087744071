<template>
	<div class="container mh-65">
		<header class="row">
			<div class="col-sm-12 col-md-6">
				<h2>Employee/Candidate Profile</h2>
				<h3>{{full_name}}</h3>
			</div>
			<div class="col-sm-12 col-md-6 text-end">
				<button type="button" class="btn btn-light mt-1 ms-1" v-show="employee.suspend==0" @click="suspend_employee(true)"><i class="bi bi-person-dash"></i> Suspend Access</button>
				<button type="button" class="btn btn-danger mt-1 ms-1" v-show="employee.suspend==1" @click="suspend_employee(false)"><i class="bi bi-person-dash"></i> Suspended</button>
				<button type="button" class="btn btn-dark mt-1 ms-1" v-show="employee.locked==1" @click="lock_employee(false)"><i class="bi bi-lock"></i> Locked</button>
				<button type="button" class="btn btn-light mt-1 ms-1" v-show="employee.locked!=1" @click="lock_employee(true)"><i class="bi bi-unlock"></i> UnLocked</button>
				<button type="button" class="btn btn-light mt-1 ms-1" @click="delete_employee"><i class="bi bi-trash"></i> Delete</button>
				<button type="button" class="btn btn-light mt-1 ms-1" @click="$router.go(-1)"><i class="bi bi-box-arrow-left"></i> Close and Return to List</button>
			</div>
		</header>
		<hr />
		<div class="mb-2">
			Access Code: 
			<div class="input-group">
				<input type="text" class="form-control" v-model="employee.access_code" readonly />
				<ClipLink :clip="employee.access_code" type="button" text="Copy" />
			</div>
		</div>
		<section id="profile">
			<div class="row">
				<div class="col-sm-12 col-md-6">
					<div class="p-2 m-1 border">
						<div class="row">
							<div class="col"><label class="form-label">Full Name</label></div>
							<div class="col"><strong>{{full_name}}</strong></div>
						</div>
						<div class="row">
							<div class="col"><label class="form-label">Preferred Name</label></div>
							<div class="col"><strong>{{employee.preferred_name}}</strong></div>
						</div>
						<div class="row">
							<div class="col"><label class="form-label">Previous Last Name</label></div>
							<div class="col"><strong>{{employee.previous_last_name}}</strong></div>
						</div>
						<div class="row">
							<div class="col"><label class="form-label">Gender</label></div>
							<div class="col"><strong>{{employee.gender}}</strong></div>
						</div>
						<div class="row">
							<div class="col"><label class="form-label">Date Of Birth</label></div>
							<div class="col"><strong>{{format_date_readable(employee.birth)}}</strong></div>
						</div>
						<div class="row">
							<div class="col"><label class="form-label">SIN</label></div>
							<div class="col"><strong>{{employee.sin_number}}</strong></div>
						</div>
						<div class="row">
							<div class="col"><hr /></div>
						</div>
						<div class="row">
							<div class="col"><label class="form-label">Invite Sent</label></div>
							<div class="col"><strong>{{format_date(employee.invited)}}</strong></div>
						</div>
						<div class="row">
							<div class="col"><label class="form-label">Created</label></div>
							<div class="col"><strong>{{format_date(employee.created)}}</strong></div>
						</div>
						<div class="row">
							<div class="col"><label class="form-label">Last Updated</label></div>
							<div class="col"><strong>{{format_date(employee.updated)}}</strong></div>
						</div>
						<div class="row">
							<div class="col"><label class="form-label">Last Logged in</label></div>
							<div class="col"><strong>{{format_date(employee.last_login)}}</strong></div>
						</div>
						<div class="row">
							<div class="col"><hr /></div>
						</div>
						<div class="row">
							<div class="col"><label class="form-label">Primary Phone</label></div>
							<div class="col"><strong>{{employee.primary_contact_phone}}</strong></div>
						</div>
						<div class="row">
							<div class="col"><label class="form-label">Primary Email</label></div>
							<div class="col overflow-hidden"><a :href="'mailto:'+employee.primary_contact_email">{{employee.primary_contact_email}}</a></div>
						</div>
						<div class="row">
							<div class="col"><label class="form-label">Street</label></div>
							<div class="col"><strong>{{employee.street}}</strong></div>
						</div>
						<div class="row">
							<div class="col"><label class="form-label">City</label></div>
							<div class="col"><strong>{{employee.city}}</strong></div>
						</div>
						<div class="row">
							<div class="col"><label class="form-label">Province</label></div>
							<div class="col"><strong>{{employee.province}}</strong></div>
						</div>
						<div class="row">
							<div class="col"><label class="form-label">Postal Code</label></div>
							<div class="col"><strong>{{employee.postal_code}}</strong></div>
						</div>
						<div class="row">
							<div class="col"><hr /></div>
						</div>
						<div class="row">
							<div class="col"><label class="form-label">Emergency Contact 1</label></div>
							<div class="col">&nbsp;</div>
						</div>
						<div class="row">
							<div class="col"><label class="form-label">Name</label></div>
							<div class="col"><strong>{{employee.emergency_contact1_name}}</strong></div>
						</div>
						<div class="row">
							<div class="col"><label class="form-label">Phone</label></div>
							<div class="col"><strong>{{employee.emergency_contact1_phone}}</strong></div>
						</div>
						<div class="row">
							<div class="col"><label class="form-label">Relationship</label></div>
							<div class="col"><strong>{{employee.emergency_contact1_relationship}}</strong></div>
						</div>
						<div class="row">
							<div class="col"><hr /></div>
						</div>
						<div class="row">
							<div class="col"><label class="form-label">Emergency Contact 2</label></div>
							<div class="col">&nbsp;</div>
						</div>
						<div class="row">
							<div class="col"><label class="form-label">Name</label></div>
							<div class="col"><strong>{{employee.emergency_contact2_name}}</strong></div>
						</div>
						<div class="row">
							<div class="col"><label class="form-label">Phone</label></div>
							<div class="col"><strong>{{employee.emergency_contact2_phone}}</strong></div>
						</div>
						<div class="row">
							<div class="col"><label class="form-label">Relationship</label></div>
							<div class="col"><strong>{{employee.emergency_contact2_relationship}}</strong></div>
						</div>
						<div class="row">
							<div class="col"><hr /></div>
						</div>
						<div class="row">
							<div class="col"><label class="form-label">Regulatory Membership Certificate No</label></div>
							<div class="col"><strong>{{employee.regulatory_membership_cert}}</strong></div>
						</div>
						<div class="row">
							<div class="col"><label class="form-label">Work Eligibility</label></div>
							<div class="col"><strong>{{employee.work_eligibility}}</strong></div>
						</div>
						<div class="row">
							<div class="col"><label class="form-label">French Language Level</label></div>
							<div class="col"><strong>{{employee.french_language_level}}</strong></div>
						</div>
						<div class="row">
							<div class="col"><label class="form-label">Police Check</label></div>
							<div class="col"><strong>{{employee.police_record_check_status}}</strong></div>
						</div>
						<div class="row">
							<div class="col"></div>
							<div class="col">
								<button type="button" class="btn btn-light btn-sm" @click="download_pdf('employeeprofile')"><i class="bi bi-filetype-pdf"></i> View PDF</button>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<hr />
								<h4>Signatures</h4>
							</div>
						</div>
						<div class="row">
							<div class="col"><label class="form-label">New Hire Attestation</label></div>
							<div class="col"><strong>{{employee.newhire_attestation_date}}</strong></div>
							<div class="col"><button v-if="employee.newhire_attestation" type="button" class="btn btn-light btn-sm" @click="download_pdf('newhire')"><i class="bi bi-eye"></i> View</button></div>
						</div>
						<div class="row">
							<div class="col"><label class="form-label">Confidentiality Agreement</label></div>
							<div class="col"><strong>{{employee.confidentiality_agreement_date}}</strong></div>
							<div class="col"><button v-if="employee.confidentiality_agreement" type="button" class="btn btn-light btn-sm" @click="download_pdf('confidentiality')"><i class="bi bi-eye"></i> View</button></div>
						</div>
						<div class="row">
							<div class="col"><label class="form-label">Values Agreement</label></div>
							<div class="col"><strong>{{employee.values_agreement_date}}</strong></div>
							<div class="col"><button v-if="employee.values_agreement" type="button" class="btn btn-light btn-sm" @click="download_pdf('values')"><i class="bi bi-eye"></i> View</button></div>
						</div>
						<div class="row">
							<div class="col"><label class="form-label">Parking Debit Authorization</label></div>
							<div class="col"><strong>{{employee.parking_debit_authorization_date}}</strong></div>
							<div class="col"><button v-if="employee.parking_debit_authorization" type="button" class="btn btn-light btn-sm" @click="download_pdf('parking')"><i class="bi bi-eye"></i> View</button></div>
						</div>
						<div class="row">
							<div class="col"><label class="form-label">Direct Deposit Authorization</label></div>
							<div class="col"><strong>{{employee.direct_deposit_authorization_date}}</strong></div>
							<div class="col"><button v-if="employee.direct_deposit_authorization" type="button" class="btn btn-light btn-sm" @click="download_pdf('directdeposit')"><i class="bi bi-eye"></i> View</button></div>
						</div>
						<div class="row">
							<div class="col"><label class="form-label">Foundation Staff Lottery Enrolment</label></div>
							<div class="col"><strong>{{employee.foundation_lottery_date}}</strong></div>
							<div class="col"><button v-if="employee.foundation_lottery" type="button" class="btn btn-light btn-sm" @click="download_pdf('foundationlottery')"><i class="bi bi-eye"></i> View</button></div>
						</div>
						<div class="row">
							<div class="col"><label class="form-label">HOOPP Authorization</label></div>
							<div class="col"><strong>{{employee.hoop_authorization_date}}</strong></div>
							<div class="col"><button v-if="employee.hoop_authorization" type="button" class="btn btn-light btn-sm" @click="download_pdf('hoopauthorization')"><i class="bi bi-eye"></i> View</button></div>
						</div>
					</div>
				</div>
				<div class="col-sm-12 mt-3 mb-3 d-sm-block d-md-none"><hr /></div>
				<div class="col-sm-12 col-md-6">
					<div class="p-2 m-1 border">
						<div class="row" v-for="icon,idx in icons" :key="idx">
							<div class="col col-md-8">
								<i class="bi" :class="icon_class(icon)"></i> {{icon.caption}}
							</div>
							<div class="col d-flex justify-content-end">
								<div v-show="icon.completed>=100" class="badge bg-success text-white mt-1">
									<i class="bi bi-check2-circle"></i> 100 %
								</div>
								<div v-show="icon.completed<100" class="badge bg-warning text-black mt-1">
									{{icon.completed}} %
								</div>
							</div>
						</div>
					</div>
					<div class="d-flex flex-column">
						<button type="button" class="btn btn-light mt-1 ms-1" @click="login_as_user"><i class="bi bi-person-circle"></i> Login as User</button>
						<!-- <button type="button" class="btn btn-light mt-1 ms-1" @click="download_archive"><i class="bi bi-download"></i> Download File Package</button> -->
						<a class="btn btn-light mt-1 ms-1" :href="download_archive_url(employee.employee_id)" target="_blank"><i class="bi bi-download"></i> Download File Package</a>
						<button type="button" class="btn btn-light mt-1 ms-1" @click="reset_code"><i class="bi bi-cloud-arrow-up"></i> Send New Access Code</button>
					</div>
					<section id="downloads" class="mt-4">
						<h3>Documents</h3>
						<table class="table">
							<thead>
								<tr>
									<th>File</th>
									<th colspan="2">Type</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="d,idx in employee_documents" :key="idx">
									<td>{{d.filename}}</td>
									<td>{{d.document_type_id}}</td>
									<td>
										<button type="button" class="btn btn-light ms-1 mt-1" title="Download Document" @click="download_employee_document(d.employee_document_id)"><i class="bi bi-download"></i><span class="d-none d-md-inline"> Download</span></button>
										<!-- <button type="button" class="btn btn-light ms-1 mt-1" title="View Document in Browser"><i class="bi bi-eye"></i><span class="d-none d-md-inline"> View</span></button> -->
									</td>
								</tr>
							</tbody>
						</table>
					</section>
				</div>
			</div>
		</section>
		<hr />
		

	</div>
</template>
<script>
	import ClipLink from '@/components/ClipLink.vue'
	export default{
		name: 'EmployeeProfileView',
		components: {ClipLink},
		data(){return{
			stats: {},
			icons: [],
			employee: {},
			employee_loaded: false,
			user: {},
			employee_documents: [],
			access_code: null,
		}},
		computed: {
			full_name(){
				let name = ''
				let v = this
				if( v.employee.given_name )
					name += v.employee.given_name
				if( v.employee.middle_name )
					name += ' ('+v.employee.middle_name+')'
				if( v.employee.last_name )
					name += ' '+v.employee.last_name
				return name
			},
		},
		methods:{
			icon_class(icon){
				let v = this
				let result = {}
				result[icon.icon] = true
				return result
			},
			suspend_employee(suspend){
				let v = this
				v.employee.suspend = suspend ? 1 : 0;
				v.save_employee()
			},
			lock_employee(lock){
				let v = this
				if( lock )
					v.axios.post('api/employee/lock/'+String(v.employee.employee_id),{silent:true}).then(response=>{
						v.employee.locked = 1;
					})
				else
					v.axios.post('api/employee/unlock/'+String(v.employee.employee_id)).then(response=>{
						v.employee.locked = 0;
					})
			},
			save_employee(){
				let v = this

				// Additional fields added to the employee record for display purposes have to be removed before sending
				let payload = {}
				Object.assign(payload,v.employee)
				//delete payload.full_name

				v.axios.post('api/employee',payload).then(response=>{
					v.employee = response.data.employee
					v.stats = response.data.stats
					v.$toast('Changes Saved','success')
				})
			},
			delete_employee(){
				let v = this
				v.$prompt({
					text: "Are you certain you wish to delete this Employee/Candidate? This operation cannot be un-done! Enter the word YES to Delete this Profile.",
					success: result=>{
						if( String(result).match(/[yY][eE][sS]/) )
						{
							v.axios.delete('api/employee/'+String(v.employee.employee_id)).then(response=>{
								v.$toast('Employee Deleted','success')
								v.$router.go(-1)
							})
						}
						else
							v.$toast('Cancelled','info');
					},
					cancel: ()=>{
						v.$toast('Cancelled','info');
					},
				})
			},
			load_employee(employee_id){
				let v = this
				v.axios.get('api/employee/'+String(employee_id)).then(response=>{
					v.employee = response.data.employee
					if( v.employee.suspend )
						v.employee.suspend = parseInt(v.employee.suspend)
					v.stats = response.data.stats
					v.icons = [
						{caption:'New Hire Information', icon: 'bi-hospital', completed: v.stats.employee_percent, to: '/employee#top'},
						{caption:'Police Check', icon: 'bi-person-lock', completed: v.stats.police_percent, to: '/policecheck#top'},
						{caption:'French Aptitude', icon: 'bi-chat', completed: v.stats.french_percent, to: '/french#top'},
						{caption:'Certificates', icon: 'bi-award', completed: v.stats.qualifications_percent, to: '/certificates#top'},
						{caption:'Health and Safety', icon: 'bi-shield-shaded', completed: v.stats.health_safety_quiz_percent, to: '/healthandsafety'},
						{caption:'New Hire Attestation', icon: 'bi-ui-checks', completed: v.stats.attestation_percent, to: '/attestation'},
						{caption:'Confidentiality Agreement', icon: 'bi-file-lock', completed: v.stats.confidentiality_percent, to: '/confidentiality'},
						{caption:'Values and Behaviours', icon: 'bi-card-checklist', completed: v.stats.values_percent, to: '/values'},
						{caption:'TD1 Forms', icon: 'bi-file-earmark-text', completed: v.stats.td1_forms_percent, to: '/td1forms'},
						{caption:'Parking', icon: 'bi-p-circle', completed: v.stats.parking_percent, to: '/parking'},
						{caption:'Foundation', icon: 'bi-building-add', completed: v.stats.foundation_lottery_percent, to: '/parking'},
						{caption:'Direct Deposit', icon: 'bi-cash-coin', completed: v.stats.direct_deposit_percent, to: '/directdeposit'},
						{caption:'HOOPP',icon: 'bi-cash-coin', completed: v.stats.hoop_authorization_percent, to: '/hoopauth'},
					]
					// Important because of Boolean checks on the values in the view code
					v.employee.newhire_attestation = parseInt(v.employee.newhire_attestation)
					v.employee.confidentiality_agreement = parseInt(v.employee.confidentiality_agreement)
					v.employee.values_agreement = parseInt(v.employee.values_agreement)
					v.employee.parking_debit_authorization = parseInt(v.employee.parking_debit_authorization)
					v.employee.direct_deposit_authorization = parseInt(v.employee.direct_deposit_authorization)
					v.load_employee_documents(employee_id)
				})
			},
			load_employee_documents(employee_id){
				let v = this
				v.axios.get('api/employee/documents/'+String(employee_id)).then(response=>{
					v.employee_documents = response.data
				})
			},
			reset_code(){
				let v = this
				v.$confirm({
					text: 'This will send the user a new access code and remove any previous code assigned to them for access. Are you sure you want to proceed?',
					success: ()=>{
						v.axios.get('api/employee/resendcode/'+String(v.employee.employee_id)).then(response=>{
							v.$toast('New Access code generated and sent.','info')
						})
					},
				})
			},
			format_date(date){
				if (date == null || date == "")
				{
					return ""
				}
				else
				{
					let d = new Date(date)

					return d.toLocaleString()
				}
			},
			format_date_readable(date){
				if (date == null || date == "")
				{
					return ""
				}
				else
				{
					let d = new Date(date)

					return d.toLocaleString([], {year: "numeric", month: "long", day: "numeric"})
				}
			},
			/**
			 * Triggers a Logout and Authenticates as the User in Question
			 */
			login_as_user(){
				// sends login submission to the server
				let v = this

				v.axios.get('api/employee/get_code/'+String(v.employee.employee_id)).then(response=>{
					if( response.data )
					{
						v.access_code = response.data.access_code

						v.axios.post('api/authcode',{access_code:v.access_code,user_id:v.user.id}).then(response=>{
							if( !response )
							{
								v.msg = "Access Code Invalid, Try again or contact an administrator for help."
								return
							}
							if(response.data.token)
							{
								v.set_token(response.data.token)
								// this method of redirect is required to refresh the session after setting the cookie
								window.location.href = '/'
							}
						}).catch(error=>{
							console.log(error)
						})
					}
				})
			},
			/**
			 * Triggers streamed download of the generated PDF for the given attestation document
			 */
			download_pdf(type){
				let v = this
				let employee_id = v.employee.employee_id
				let token = v.get_token()
				window.open(process.env.VUE_APP_API_BASE_URL+'/pdf/preview?type='+String(type)+'&employee_id='+String(employee_id)+'&token='+token, '_blank')
			},
			download_archive_url(){
				let v = this
				let token = v.get_token()
				// window.open(process.env.VUE_APP_API_BASE_URL+'/api/employee/dlzip/'+String(v.employee.employee_id)+'?token='+token,'_blank')
				return process.env.VUE_APP_API_BASE_URL+'/api/employee/dlzip/'+String(v.employee.employee_id)+'?token='+token;
			},
			download_employee_document(employee_document_id){
				let v = this
				let token = v.get_token()
				window.open(process.env.VUE_APP_API_BASE_URL+'/api/employee/download/'+String(employee_document_id)+'?token='+token)
			},
		},
		mounted(){
			let v = this
			v.user = v.get_token_info()

			v.employee.employee_id = v.$route.params.id

			v.load_employee(v.employee.employee_id)
		}
	}
</script> 
