import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './app.scss'
import bootstrap from 'bootstrap'
import 'bootstrap-icons/font/bootstrap-icons.css'
import Cookies from 'js-cookie'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import {ajax,kill_token,parse_token,get_token,get_token_info,set_token} from '@/ajax.js'

const app = createApp(App)

app.use(router)
app.use(VueSweetalert2)
app.config.globalProperties.axios = ajax
app.config.globalProperties.kill_token = kill_token
app.config.globalProperties.cookies = Cookies
app.config.globalProperties.router = router
app.config.globalProperties.decode_token = parse_token
app.config.globalProperties.get_token = get_token
app.config.globalProperties.set_token = set_token
app.config.globalProperties.get_token_info = get_token_info
app.config.globalProperties.formats = {
	date: {
		mysql: 'YYYY-MM-DD',
		html: 'YYYY-MM-DD',
	},
	datetime: {
		mysql: 'YYYY-MM-DD hh:mm:ss',
		html: 'YYYY-MM-DD HH:mm',
	},
}
app.mount('#app')


// Setup Basic Toasts using SweetAlert2
// https://github.com/avil13/vue-sweetalert2
app.config.globalProperties.$toast = function(text,type,options){
	let opts = {
					icon: 'info',
					text: 'Something has happened',
					toast: true,
					position: 'top-end',
					timer: 2000,
					timerProgressBar: true,
					showConfirmButton: false,
				}
	if( text )
		opts.text = text
	if( type )
		opts.icon = type
	if(options && options.length > 0)
		for(let i in options)
			opts[i] = options[i]
	this.$swal(opts)
}

// Setup Custom Dialog Prompts using SweetAlert2
// https://github.com/avil13/vue-sweetalert2

// EXAMPLE of the ALERT dialog
// v.$confirm({
// 	text: 'Are you sure you wish to remove all of the selected employees from this week?',
// 	title: 'Remove Employees from Week',
// 	success(){
// 		v.axios.post('/api/job/employee/delete',payload).then(response=>{
// 			v.load_employees(v.week.job_week_id, v.job.job_id)
// 		})
// 	},
// })
app.config.globalProperties.$alert = function(text,options){
	let opts = {
					icon: 'warning',
					text: 'Warning, something is up!',
					showConfirmButton: true,
					confirmButtonText: 'Ok',
				}
	if(text)
		opts.text = text
	this.$swal(opts)
}

// EXAMPLE of the CONFIRM dialog usage
// 	this.$confirm({
// 		text: 'Are you sure about this?',
// 		success: result=>{
// 			this.$toast('Apparently you are!','success')
// 		},
// 	})
app.config.globalProperties.$confirm = function(options){
	let opts = {
					icon: 'question',
					text: 'Are you sure you wish to continue?',
					showConfirmButton: true,
					showCancelButton: true,
				}
	for(let o in options)
		if( !['success','cancel'].includes(o) )
			opts[o] = options[o] // override opts with provided values
	this.$swal(opts).then(result=>{
		if( result.isConfirmed && typeof options.success == 'function' )
			options.success(result.value)
		if( !result.isConfirmed && typeof options.cancel == 'function' )
			options.cancel()
	})
}

// EXAMPLE of the PROMPT dialog usage
// this.$prompt({
// 	text: 'Enter DELETE to continue:',
// 	success: result=>{
// 		if( result == 'DELETE' )
// 			this.$toast('Yay','success')
// 		else
// 			this.$toast('Nope','error')
// 	},
// 	cancel: result=>{
// 		this.$toast('Cancel Pressed','info')
// 	},
// })
app.config.globalProperties.$prompt = function(options){
	let opts = {
					input: 'text',
					inputLabel: 'Please enter a value to continue:',
					inputValue: '',
					showConfirmButton: true,
					showCancelButton: true,
					icon: 'question',
				}
	for(let o in options)
		if( !['success','cancel'].includes(o) )
			opts[o] = options[o] // override opts with provided values
	this.$swal(opts).then(result=>{
		if( result.isConfirmed && typeof options.success == 'function' )
			options.success(result.value)
		if( !result.isConfirmed && typeof options.cancel == 'function' )
			options.cancel()
	})
} 

