<style scoped>
	.bg-lang0{background-color: #E0E4EE;}
	.bg-lang1{background-color: #CCCDE2;}
	.bg-lang2{background-color: #BBB8D5;}
	.bg-lang3{background-color: #AFA4C9;}
	.bg-lang4{background-color: #A690BC;}
	.bg-lang5{background-color: #9F7DAF;}
	.bg-lang6{background-color: #9A6AA2;}
	.hover:hover{cursor:pointer; opacity:0.9;}
</style>
<template>
	<div class="container mh-65" id="top">
		<h1>French</h1>
		<section v-if="employee_loaded">
			<h4>French Language Competancy</h4>
			<p>In order to better serve Francophone patients, the Local Health Integration Network requires that each organization reports on their bilingual (English and French) human resources capacity.</p>
			<p>Based on the definitions provided, please indicate your linguistic abilities.</p>
			<!--:class="lang_bg_class(idx)"-->
			<div class="row hover border rounded"  v-for="lang,idx in language_levels" :key="idx" v-show="employee.french_language_level==null || employee.french_language_level==lang.value">
				<div class="col-sm-3 col-md-2 pt-2 pb-2">
					<div class="w-100 h-100 d-flex align-items-center">
						<div class="form-check">
							<label class="form-check-label" :for="'lang_chk'+String(idx)"><strong>{{lang.label}}</strong></label>
							<input type="radio" class="form-check-input" :id="'lang_chk'+String(idx)" :value="lang.value" v-model="employee.french_language_level" :aria-label="lang.label+' in the French Language'">
						</div>
					</div>
				</div>
				<div class="col">
					<p @click="employee.french_language_level=lang.value">{{lang.description}}</p>
				</div>
			</div>
			<button type="button" v-show="employee.french_language_level!=null" class="mt-2 btn btn-sm btn-light" @click="employee.french_language_level=null">Change Language Level</button>
		</section>
		<hr />
		<div class="d-flex justify-content-center justify-content-md-end mt-5 mb-5">
			<router-link type="button" class="btn btn-light mt-1 me-1" to="/">Cancel, Go Back</router-link>
			<button type="button" class="btn btn-light mt-1 me-1" @click="save_employee(true)">Save &amp; Stay on the Page</button>
			<button type="button" class="btn btn-light mt-1" @click="save_employee(false)">Save &amp; Go Back</button>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'FrenchLanguageView',
		data(){return{
			employee: {},
			stats: {},
			employee_loaded: false,
			language_levels: [
				{value:'no-proficiency',label:'No Proficiency',description:'At this level, the individual possesses no ability to work or communicate in French.'},
				{value:'elementary',label:'Elementary',description:"At this level, the individual has no real autonomy of expression. The ability to speak is limited to some memorized material on familiar topics related to work. The individual is able to verbalize isolated words, expressions of two or three words, and express simple, unconnected sentences. The range of vocabulary is limited and the delivery is slow and awkward. The individual can handle greetings, leave taking, and other expressions of courtesy. The limited vocabulary, the frequent errors, and slow delivery severely inhibit communication."},
				{value:'intermediate',label:'Intermediate',description:"At this level, the individual possesses some ability to work in French. The individual shows some spontaneity in language production but the fluency is very uneven resulting in halting speech. The individual is able to participate in simple conversations on a one-to-one basis. The vocabulary is limited to that used in simple, non-technical, daily conversational usage. The individual can make and answer requests for information or directions, give simple instructions and discuss simple needs. When addressing this person the speaker may have to slow down and repeat if he/she wishes to be understood."},
				{value:'advanced-minus',label:'Advanced Minus',description:"At this level, the individual has the ability to handle a variety of communication tasks. The individual is able to describe and explain in all timeframes in most informal and some formal situations across a variety of familiar topics. The vocabulary often lacks specificity. Nevertheless, the individual is able to use rephrasing and paraphrasing. Although grammatical, lexical and pronunciation errors are evident, the individual can speak with enough accuracy to be understood."},
				{value:'advanced',label:'Advanced',description:"At this level, the individual has the ability to participate in conversations and satisfy many work requirements. The individual can discuss work-related matters with some ease and facility, expressing opinions and offering views. The individual is able to take part in a variety of verbal exchanges and to participate in meetings and discussion groups. However, the individual still needs help with handling complicated issues or situations. The individual is generally good in either grammar or vocabulary but not in both."},
				{value:'advanced-plus',label:'Advanced Plus',description:"At this level, the individual is able to give oral presentations in both formal and informal settings. The individual is able to present a fairly detailed outline of his/her line of reasoning on general or work-related topics in formal and informal settings, in meetings and in discussion groups. Some mastery of idioms and of specific vocabulary appropriate to a variety of contexts is evident. Grammar is generally appropriate. Deficiencies in vocabulary are compensated for by synonyms and paraphrases. Problems may be encountered when discussing more specialized topics, but the individual at this level has very little difficulty in making himself / herself understood."},
				{value:'superior',label:'Superior',description:"At this level, the individual has the ability to speak the language with sufficient structural accuracy, fluency and vocabulary to participate effectively in most formal and informal conversations on practical, social and professional topics. The individual is able to use idioms and specific vocabulary relevant to a variety of contexts and to give verbal presentations in both formal and informal settings."},
			],
		}},
		methods: {
			lang_bg_class(idx){
				let caption = 'bg-lang'+String(idx)
				let css = {}
				css[caption] = true
				return css
			},
			load_employee(employee_id){
				// Loads the Employee Record
				let v = this
				if( employee_id == 0 )
				{
					// Only the couple fields are needed for the update
					v.employee = {
						employee_id: 0,
						french_language_level: null,
					}
					v.employee_loaded = true
				}
				else
					v.axios.get('/api/employee/'+String(employee_id)).then(response=>{
						v.employee = response.data.employee
						v.stats = response.data.stats
						v.employee_loaded = true
					})
			},
			save_employee(stay_on_page=true){
				const v = this
				v.axios.post('/api/employee',v.employee).then(response=>{
					v.$toast('Information Saved','success');
					v.employee = response.data.employee
					v.stats = response.data.stats
					if( !stay_on_page )
						v.$router.push('/')
				})
			},
		},
		mounted(){
			let v = this

			v.userinfo = v.get_token_info()

			let id = v.$route.params.id

			if( id )
			{
				v.load_employee(id)
				return
			}

			if( v.userinfo.id )
				v.load_employee(v.userinfo.id)
			else
				v.load_employee(0)
		},
	}
</script>